import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export const RangeSlider = ({
  data,
  dataKey,
  setData,
  tickFormatter
}) => {
  let timer = null;

  return (
    <div className="graph-range--container">
      <Range
        key={`range-slider-${data.length}`} // Key används för att tvinga fram omrendering vid filtrering av data som visas i grafen, t.ex. toggle mellan säker/osäker data
        tipProps={{
          placement: 'bottom',
          visible: true
        }}
        tipFormatter={index => {
          let value = index;
          if (data && data[index] && data[index][dataKey]) {
            value = data[index][dataKey];
          }
          return tickFormatter ? tickFormatter(value) : value;
        } }
        allowCross={false}
        min={0}
        max={data.length - 1}
        defaultValue={[0, data.length - 1]}
        draggableTrack
        onChange={(e) => {
          clearTimeout(timer);
          timer = setTimeout(() => {                  
            const start = e[0];
            const end = e[1] + 1;
            setData(data.slice(start, end));
          }, 0);
        }}
        step={1}
        trackStyle={[{ backgroundColor: "#74BCED", cursor: "move" }]}
        railStyle={{ backgroundColor: "#D0D6DB" }}
      />
    </div>
  );
};

RangeSlider.defaultProps = {
  dataKey: "date",
  data: [],
  setData: undefined,
  tickFormatter: undefined
}
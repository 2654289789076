import React, { useRef, useEffect, useState, useCallback } from "react";
import { useMap, useMapEvent } from "react-leaflet";
import { Button } from "@aurora/widgets-react";
import { AddOutline, RemoveOutline } from "@aurora/icons-react";
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";
import "./SelectLocationMap.css";
import L from "leaflet";

function ZoomControl({ min, max }) {
  const zoomControlRef = useRef(null);
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());

  useEffect(() => {
    L.DomEvent.disableClickPropagation(zoomControlRef.current);
    L.DomEvent.disableScrollPropagation(zoomControlRef.current);
  });

  const onChange = useCallback(() => {
    setZoom(map.getZoom());
  }, [map]);

  useMapEvent("zoom", onChange);

  const zoomIn = useCallback(() => {
    map.zoomIn();
  }, [map]);

  const zoomOut = useCallback(() => {
    map.zoomOut();
  }, [map]);

  return (
    <div className="leaflet-bottom leaflet-right" ref={zoomControlRef}>
      <div className="leaflet-control zoom-control">
        <Button
          variant={Button.variants.quaternary}
          iconOnly
          label="Zooma in"
          title="Zooma in"
          onClick={zoomIn}
          icon={<AddOutline/>}
          disabled={zoom >= max}
        />
        <Button
          variant={Button.variants.quaternary}
          iconOnly
          label="Zooma ut"
          title="Zooma ut"
          onClick={zoomOut}
          icon={<RemoveOutline/>}
          disabled={zoom <= min}
        />
      </div>
    </div>
  );
}

ZoomControl.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
};

export default ZoomControl;

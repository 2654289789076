import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const PrecipitationMonthlyNormGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `precipitation/charts/${stationId}/${currentYear}`;
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1

  return <GraphURI
    uri={uri}
    bars={[
        {
            id: `Dygnsnederbörd_std_1961-1990`,
            name: `Normalvärden 1961 – 1990`,
            fill: "#74BCED",
            unit: 'mm',
            enabled: true
        },
        {
            id: "Dygnsnederbörd_std_1991-2020",
            name: "Normalvärden 1991 – 2020",
            fill: "#0C6BC4",
            unit: 'mm',
            enabled: true
        },
        {
          id: `Dygnsnederbörd_${compareYear}`,
          fill: "#002863",
          name: `Dygnsnederbörd ${compareYear}`,
          unit: "mm"
        }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    yAxisLabel='mm'
    fixedDomain={true} updateFixedDomain={true}
    filterData={filterData}
    {...props}
  />
};

export const PrecipitationMonthlyNormGraph1961_1990 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `precipitation/charts/${stationId}/${currentYear}`;
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1

  return <GraphURI
    uri={uri}
    bars={[
      {
        id: `Dygnsnederbörd_std_1961-1990`,
        name: `Normalvärden 1961 – 1990`,
        fill: "#74BCED",
        unit: 'mm',
        enabled: true
      },
      {
        id: `Dygnsnederbörd_${compareYear}`,
        fill: "#002863",
        name: `Dygnsnederbörd ${compareYear}`,
        unit: "mm"
      }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    yAxisLabel='mm'
    fixedDomain={true} updateFixedDomain={true}
    filterData={filterData}
    {...props}
  />
};

export const PrecipitationMonthlyNormGraph1991_2020 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `precipitation/charts/${stationId}/${currentYear}`;
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1

  return <GraphURI
    uri={uri}
    bars={[
      {
        id: "Dygnsnederbörd_std_1991-2020",
        name: "Normalvärden 1991 – 2020",
        fill: "#0C6BC4",
        unit: 'mm',
        enabled: true
      },
      {
        id: `Dygnsnederbörd_${compareYear}`,
        fill: "#002863",
        name: `Dygnsnederbörd ${compareYear}`,
        unit: "mm"
      }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    yAxisLabel='mm'
    fixedDomain={true} updateFixedDomain={true}
    filterData={filterData}
    {...props}
  />
};

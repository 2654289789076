import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP_LONG = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const PrecipitationDailyAverageGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `precipitation/charts/${stationId}/${currentYear}`

  const GRAPH_BARS = [
    {
      id: `Dygnsnederbörd_${currentYear - 1}`,
      fill: "#74BCED",
      name: `Dygnsnederbörd ${MONTH_MAP_LONG[currentMonth - 1]} ${currentYear - 1}`,
      unit: "mm",
      enabled: true
    },
    {
      id: `Dygnsnederbörd_${currentYear}`,
      fill: "#0C6BC4",
      name: `Dygnsnederbörd ${MONTH_MAP_LONG[currentMonth - 1]} ${currentYear}`,
      unit: "mm",
      enabled: false
    }
  ];
  return (
    <GraphURI uri={uri} dataKey="date" bars={GRAPH_BARS} {...props}
              yAxisLabel={"mm"}
      filterData={data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]))}
    />
  );
};

import {useGlobalState} from "../../state";
import {Badge} from "@aurora/widgets-react";
import './MissingDataBadge.css';

export const MissingDataBadge = ({cards}) => {
  if (cards === false) { return null;}

  const data_info = cards.meta.data_info;
  // TODO: get attribute for precipitation data_info.
  const percentage = data_info['Dygnsmedel'] ? data_info['Dygnsmedel'].percentage_of_missing_data : 0;

  if (percentage < 1) { return null; }
  return <Badge className="missing-data-badge">
    Saknade mätningar för stationen: {percentage}%
  </Badge>
}

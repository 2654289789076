import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import ResponsiveWrapper from '../ResponsiveWrapper';
import "./CardCarousel.css";

const CarouselDot = ({active, onClick}) => <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"
                               className="auIcon auIcon16 " focusable="false">
  <circle cx={8} cy={8} r={4} stroke={"none"} fill={active ? "#647078" : "#EBEEF0"}/>
</svg>


export const CarouselItem = ({ children, width }) => {
  return (
    <div className="cardcarousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventDefaultTouchmoveEvent: true
  });

  return (<>
    <ResponsiveWrapper className="cardcarousel-outer" height={300}>
      <div
        {...handlers}
        className="cardcarousel"
      >
        <div
          className="cardcarousel-inner"
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: "100%" });
          })}
        </div>
      </div>
    </ResponsiveWrapper>
    <div className="cardcarousel-indicators">
      {React.Children.map(children, (child, index) => {
        return (
          <CarouselDot
            active={index === activeIndex}
            onClick={() => {
              updateIndex(index);
            }}
          />
        );
      })}
    </div>
  </>);
};

export default Carousel;

import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const PrecipitationYearlyGraph = (props) => {
  const [stationId] = useCurrentStationIdAndYear();
  const uri = `precipitation/yearly_precipitation/${stationId}`
  return <GraphURI
    uri={uri}
    dataKey="year"
    bars={[
      {id: `annual_precipitation`, fill: "#74BCED", name: `Årsnederbörd`, unit: "mm", enabled: true},

    ]}
    lines={[
        {id: `annual_rolling_average`, stroke: "#0C6BC4", name: `Trend - årsnederbörd`, unit: "mm"},
    ]}
    yAxisLabel='mm'
    showUncertainty={true}
    {...props}
  />
};

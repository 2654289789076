import React from 'react';
import ReactDOM from 'react-dom';
import page from 'page';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CONTEXT_PATH} from "./utils";

const render = c =>
  ReactDOM.render(
    <React.StrictMode>
      {c}
    </React.StrictMode>,
    document.getElementById('hvv-app')
  );

page(`${CONTEXT_PATH}`, () => render(<App/>));
// Det behövs ett /q/ för att reglerna i Varnish för Polopoly att fungera med åäö
page(`${CONTEXT_PATH}q/:station`, ctx => render(<App station={ctx.params.station}/>));
page(`${CONTEXT_PATH}q/:station/:parameter`, ctx => render(<App station={ctx.params.station} parameter={ctx.params.parameter}/>));
page('*', () => render(<App/>));
page({ click: false });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const TemperatureYearlyAverageGraph = (props) => {
  const [stationId] = useCurrentStationIdAndYear();
  const uri = `temperature/climate/${stationId}`;
  const bars = [
    {id: "annual_average", fill: "#3B9CDF", name: "Årsmedeltemperatur", enabled: true},
  ];
  const lines = [
        {id: `annual_rolling_average`, stroke: "#0C6BC4", name: `Trend - årsmedeltemperatur`},
  ];

  return <GraphURI
      uri={uri}
      dataKey="year"
      bars={bars}
      lines={lines}
      {...props}
  />;
}

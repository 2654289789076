import {
  Row,
  Col,
  H3,
  Body1,
  Select,
  SubText,
} from "@aurora/widgets-react";
import { Card } from "@aurora/widgets-react";
import "./Card.css";
import {
  getCardsToRender,
  useCardsData,
  useCurrentCardTab, useCurrentMonth, useCurrentRoute,
  useCurrentSelectedCard,
  useParameter,
  useScreenMode
} from "../../state";
import React from "react";
import {formatPrecipitation, formatTemp, monthIndexToString, MONTHS, roundPrecipitation, roundTemp} from "../../utils";
import { MissingDataBadge } from "../MissingDataBadge";
import { InformationOutline } from "@aurora/icons-react";
import Carousel, { CarouselItem } from "./CardCarousel";

const BallText = ({x, y, color="white", children}) => {
  const [parameter] = useParameter();
  return <text
    textAnchor="middle"
    dominantBaseline="middle"
    x={x}
    y={y + 4}
    fill={color}
    fontFamily={parameter === "temperature" ? "Futura Demibold, Verdana, Arial, sans-serif" : "Futura Medium, Verdana, Arial, sans-serif"}
    fontSize={parameter === "temperature" ? '2.5rem' : '2rem'}
    fontWeight={400}
    letterSpacing={parameter === "temperature" ? "-0.03rem" : "-0.02rem"}
  >
    {children}
  </text>
}

const TemperatureIcon = () => (
  <g transform="translate(20, 20) scale(3.75)">
    <path
      fill="#B4070F"
      d="M24.5 6A1.5 1.5 0 1123 7.5 1.5 1.5 0 0124.5 6m0-2A3.5 3.5 0 1028 7.5 3.5 3.5 0 0024.5 4zM16 16.11V9A5 5 0 006 9v7.11a7 7 0 000 9.78V26h.11a7 7 0 009.78 0H16v-.11a7 7 0 000-9.78zm-1.43 8.39a5 5 0 01-7 0 5 5 0 010-7l.43-.58V9a3 3 0 016 0v7.92l.57.58a5 5 0 010 7z"
    />
    <path fill="#B4070F" d="M12 18.18V12h-2v6.18a3 3 0 102 0z" />
  </g>
);

const PrecipitationIcon = () => (
  <g transform="translate(20, 20) scale(5)">
    <path fill="#0054A6"
      d="M15 19a2 2 0 01-2-2v-3.75a4.5 4.5 0 012.33 1.41 4.39 4.39 0 013.32-1.53 4.45 4.45 0 012.34.68 9 9 0 00-8-8.75V3H11v2.06a9 9 0 00-8 8.75 4.45 4.45 0 012.34-.68 4.39 4.39 0 013.32 1.53A4.5 4.5 0 0111 13.25V17a4 4 0 008 0h-2a2 2 0 01-2 2zM12 7.35c.86 0 1.95 1.59 2.55 4.32a6.29 6.29 0 00-5.1 0C10.06 9 11.15 7.35 12 7.35zm6.37 3.79a5.88 5.88 0 00-1.81.36 13.24 13.24 0 00-1.25-3.64 7.05 7.05 0 013.06 3.28zM8.69 7.86a13.45 13.45 0 00-1.26 3.64 5.82 5.82 0 00-1.8-.36 7.1 7.1 0 013.06-3.28z"></path>
  </g>
);

const ParameterIcon = ({parameter}) => {
  switch (parameter) {
    case "temperature":
      return <TemperatureIcon />;
    case "precipitation":
      return <PrecipitationIcon />;
  }
}

const parameterBackground = (parameter) => {
  switch (parameter) {
    case "temperature":
      return {fill: "#D61720", stroke: "#B4070F"};
    case "precipitation":
      return {fill: "#0C6BC4", stroke: "#0054A6"};
  }
}

const MinMaxBall = ({ data, parameter='temperature' }) => {
  const formatValue = parameter === 'temperature' ? formatTemp : formatPrecipitation;
  const min = formatValue(data.min.value, false);
  const max = formatValue(data.max.value, false);

  return (
    <svg viewBox="0 0 160 160" className="card-ball">
      <clipPath id="cut-off">
        <rect x="0" y="0" width="160" height="80" />
      </clipPath>
      <circle
        cx="80"
        cy="80"
        r="72.5"
        fill="#EBEEF0"
        stroke="#D0D6DB"
        strokeWidth={5}
      />
      <circle
        cx="80"
        cy="80"
        r="72.5"
        {...parameterBackground(parameter)}
        strokeWidth={5}
        clipPath="url(#cut-off)"
      />
      <BallText x={80} y={55}>
        {max}
      </BallText>
      <BallText x={80} y={108} color="black">
        {min}
      </BallText>
    </svg>
  );
};


const ChangesBall = ({ data, parameter='temperature'}) => {
  const formatValue = parameter === 'temperature' ? formatTemp : formatPrecipitation;
  const roundValue = parameter === 'temperature' ? roundTemp : roundPrecipitation;
  const changes = formatValue(roundValue(data["1991-2020"]) - roundValue(data["1961-1990"]), true);

  return (
    <svg viewBox="0 0 160 160" className="card-ball">
      <circle
        cx="80"
        cy="80"
        r="72.5"
        {...parameterBackground(parameter)}
        strokeWidth={5}
      />
      <ParameterIcon parameter={parameter} />
      <BallText x={80} y={80}>
        {changes}
      </BallText>
    </svg>
  );
};

const AverageBall = ({ data, parameter='temperature' }) => {
  const formatValue = parameter === 'temperature' ? formatTemp : formatPrecipitation;
  const average = data.average !== undefined ? formatValue(data.average) : "-";
  return (<svg viewBox="0 0 160 160" className="card-ball">
      <circle
        cx="80"
        cy="80"
        r="72.5"
        {...parameterBackground(parameter)}
        strokeWidth={5}
      />
      <ParameterIcon parameter={parameter}/>
      <BallText x={80} y={80}>
        {average}
      </BallText>
    </svg>);
};

const PrecipitationRecordBall = ({ data, parameter='temperature' }) => {
  const average = data.max !== undefined ? formatPrecipitation(data.max.value) : "-";
  return (<svg viewBox="0 0 160 160" className="card-ball">
    <circle
      cx="80"
      cy="80"
      r="72.5"
      {...parameterBackground(parameter)}
      strokeWidth={5}
    />
    <ParameterIcon parameter={parameter}/>
    <BallText x={80} y={80}>
      {average}
    </BallText>
  </svg>);
};

const NumberBall = ({ data, parameter='temperature' }) => {
  const formatValue = parameter === 'temperature' ? formatTemp : formatPrecipitation;
  const changes = formatValue(data);
  return (
    <svg viewBox="0 0 160 160" className="card-ball">
      <circle
        cx="80"
        cy="80"
        r="72.5"
        {...parameterBackground(parameter)}
        strokeWidth={5}
      />
      <ParameterIcon parameter={parameter}/>
      <BallText x={80} y={80}
      >
        {changes}
      </BallText>
    </svg>
  );
};

const CardHolder = ({ children, label, index, cardInfoText }) => {
  const [currentCard, setCurrentCard] = useCurrentSelectedCard();
  const selected = currentCard === index;

  const onClickCard = () => {
    setCurrentCard(index);
  }

  const onKeyPress = ({key}) => key === 'Enter' && onClickCard();

  return (
    <Col xxs={12} m={6} l={4} onClick={onClickCard} onKeyPress={onKeyPress} style={{width: "100%"}}>
      <Card className={"card" + (selected ? " selected" : "")} tabIndex="0" id={"card" + index}>
        <H3>{label}</H3>
        {/* <div
          className={"card-flip " + (selected ? "selected" : "")}
        >
          <GraphOutline32 />
        </div> */}
        {children}
      </Card>
    </Col>
  );
};

const MinMaxCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  const minLabel = data.min.timestamp;
  const maxLabel = data.max.timestamp;

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <MinMaxBall data={data} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
        <Body1>Högst: {maxLabel}</Body1>
        <Body1>Lägst: {minLabel}</Body1>
      </div>
    </CardHolder>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ChangesCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  // const [currentMonth] = useGlobalState("currentMonth");
  // const period1 = formatTemp(data["1961-1990"]);
  // const period2 = formatTemp(data["1991-2020"]);
  // const month = capitalizeFirstLetter(monthIndexToString(currentMonth));

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <ChangesBall data={data} index={index} parameter={parameter} />
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
        {/* <Paragraph>
          {month} 1961-1990: {period1}
        </Paragraph>
        <Paragraph>
          {month} 1991-2020: {period2}
        </Paragraph> */}
      </div>
    </CardHolder>
  );
};

const YearlyChangesCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  // const period1 = formatTemp(data["1961-1990"]);
  // const period2 = formatTemp(data["1991-2020"]);

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <ChangesBall data={data} index={index} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
        {/* <Paragraph>1961-1990: {period1}</Paragraph>
        <Paragraph>1991-2020: {period2}</Paragraph> */}
      </div>
    </CardHolder>
  );
};

const AverageCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <AverageBall data={data} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
      </div>
    </CardHolder>
  );
};

const PrecipitationRecordCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <PrecipitationRecordBall data={data} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        <Body1>{data.max.timestamp}</Body1>
      </div>
    </CardHolder>
  );
};



const NormTempCard1961 = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  const period1 = data["1961-1990"];

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <NumberBall data={period1} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
      </div>
    </CardHolder>
  );
};

const NormTempCard1991 = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  const period2 = data["1991-2020"];

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <NumberBall data={period2} parameter={parameter}/>
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
      </div>
    </CardHolder>
  );
};

const MonthlyMinMaxCard = ({ label, subLabel, data, index, cardInfoText, parameter }) => {
  const minLabel = data.min.timestamp;
  const maxLabel = data.max.timestamp;

  return (
    <CardHolder index={index} label={label} cardInfoText={cardInfoText}>
      <MinMaxBall data={data} parameter={parameter} />
      <div className="card-holder-bottom-text">
        {subLabel && <Body1>{subLabel}</Body1>}
        <Body1>Högst: {maxLabel}</Body1>
        <Body1>Lägst: {minLabel}</Body1>
      </div>
    </CardHolder>
  );
};

const CARDS_MAP = {
  MinMaxCard: MinMaxCard,
  YearlyChangesCard: YearlyChangesCard,
  ChangesCard: ChangesCard,
  AverageCard: AverageCard,
  PrecipitationRecordCard: PrecipitationRecordCard,
  MonthlyMinMaxCard: MonthlyMinMaxCard,
  NormTempCard1961: NormTempCard1961,
  NormTempCard1991: NormTempCard1991,
};

const TABS = {
  YEAR: { key: 0, value: 'År'},
  MONTH: { key: 1, value: 'Månad'}
};

const PERIODS = [{ key: TABS.YEAR.key, value: "Hela året" }, ...MONTHS];

const SelectCardParameter = () => {
  const [parameter, setParameter] = useParameter();
  const handleChange = (e) => {
    setParameter(e.target.value);
  };
  return (
    <Select
      className="cards-toolbar-select-period"
      value={parameter}
      onChange={handleChange}
      list={[{key: "temperature", value: "Temperatur"}, {key:"precipitation", value: "Nederbörd"}]}
      label="Parameter"
      placeholder={"Välj..."}
    />
  );
}

const SelectCardPeriod = ({ currentTab, setCurrentTab }) => {
  const [currentMonth, setCurrentMonth] = useCurrentMonth();

  const handleChange = (e) => {
    const { value } = e.target;

    if(value !== TABS.YEAR.key) {
      setCurrentMonth(value);
      if(currentTab !== TABS.MONTH.key) {
        setCurrentTab(TABS.MONTH.key);
      }
    } else {
      setCurrentTab(value);
    }
  };

  const currentPeriod = currentTab === TABS.YEAR.key ? TABS.YEAR.key : currentMonth;

  return (
    <Select
      className="cards-toolbar-select-period"
      value={currentPeriod}
      onChange={handleChange}
      list={PERIODS}
      label="Tidsperiod"
      placeholder={"Välj..."}
    />
  );
};

const DesktopCards = ({cards, parameter}) => {
  const [currentMonth] = useCurrentMonth();
  const [station] = useCurrentRoute();
  const [currentTab] = useCurrentCardTab();
  if (cards === false || cards.parameter !== parameter) {
    return null;
  }
  const renderCardLabel = (label, data) =>
    label
      .replace("YEAR", data.year)
      .replace("MONTH_CAPITALIZED", capitalizeFirstLetter(monthIndexToString(currentMonth)))
      .replace("MONTH", monthIndexToString(currentMonth))
      .replace("LOCATION", station)
      .split("\n")
      .map((line) => (
        <>
          {line}
          <br />
        </>
      ));

  let cardsToRender = getCardsToRender();

  return (
    <>
      <Row className="card-row">
        {cardsToRender.map(({ label, subLabel, data, card, cardInfoText }, index) => {
          const Card = CARDS_MAP[card];
          return (
            <Card
              key={`card-${currentTab}-${index}`}
              index={index}
              label={renderCardLabel(label, cards.data[data])}
              subLabel={subLabel ? renderCardLabel(subLabel, cards.data[data]) : undefined}
              data={cards.data[data]}
              cardInfoText={cardInfoText}
              parameter={parameter}
            />
          );
        })}
      </Row>
      <div className="card-info-text">
        <SubText>
          <InformationOutline />
          Klicka på korten ovan för mer info
        </SubText>
      </div>
      {/*<pre>{JSON.stringify(cards, "\n", "  ")}</pre>*/}

    </>
  );
};


const CarouselCards = ({cards, parameter}) => {
  const [currentMonth] = useCurrentMonth();
  const [station] = useCurrentRoute();
  const [currentTab] = useCurrentCardTab();
  if (cards === false || cards.parameter !== parameter) {
    return null;
  }

  const renderCardLabel = (label, data) =>
    label
      .replace("YEAR", data.year)
      .replace("MONTH_CAPITALIZED", capitalizeFirstLetter(monthIndexToString(currentMonth)))
      .replace("MONTH", monthIndexToString(currentMonth))
      .replace("LOCATION", station)
      .split("\n")
      .map((line) => (
        <>
          {line}
          <br />
        </>
      ));

  let cardsToRender = getCardsToRender();

  return (
    <>
      <Carousel>
      {cardsToRender.map(({ label, subLabel, data, card, cardInfoText }, index) => {
        const Card = CARDS_MAP[card];
        return (
          <CarouselItem>
          <Card
            key={`card-${currentTab}-${index}`}
            index={index}
            label={renderCardLabel(label, cards.data[data])}
            subLabel={subLabel ? renderCardLabel(subLabel, cards.data[data]) : undefined}
            data={cards.data[data]}
            cardInfoText={cardInfoText}
            parameter={parameter}
          />
          </CarouselItem>
        );
      })}
      </Carousel>
      <div className="card-info-text">
        <SubText>
          <InformationOutline />
          Klicka på korten ovan för mer info
        </SubText>
      </div>
    </>
  );
};

const Cards = () => {
  const [cards] = useCardsData();
  const [currentTab, setCurrentTab] = useCurrentCardTab();
  const [parameter] = useParameter();
  const { isDesktop } = useScreenMode();
  let content = isDesktop ? <DesktopCards cards={cards} parameter={parameter}/> : <CarouselCards cards={cards} parameter={parameter}/>

  return (
    <div id="cards-holder" className="cards-holder">
      <div>
        <nav className="card-title-bar">
          <SelectCardParameter/>
          <SelectCardPeriod currentTab={currentTab} setCurrentTab={setCurrentTab} />
          <div className="flex-line-break" />
          <MissingDataBadge cards={cards}/>
        </nav>
        {content}
      </div>
    </div>
  );
}

export default Cards;

import {Select} from "@aurora/widgets-react";
import {MONTHS} from "../../utils";
import {useEffect, useState} from "react";
import './DatePicker.css'

function getDaysInMonth(month) { // Use 1 for January, 2 for February, etc.
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, month, 0).getDate();
}

function datesDiffer(d1, d2) {
  return d1.month !== d2.month || d1.day !== d2.day
}

export const DatePicker = ({ currentDate, setCurrentDate }) => {
  const [uiDate, setUiDate] = useState({month: false, day: false});
  const updateMonth = e => setUiDate({...uiDate, month: e.target.value});
  const updateDay = e => setUiDate({...uiDate, day: e.target.value});

  useEffect(() => {
    if (uiDate.month !== false && uiDate.day !== false) {
      if (datesDiffer(uiDate, currentDate)) {
        setCurrentDate({...uiDate});
      }
    }
  }, [uiDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    if (currentDate !== false) {
      if (datesDiffer(uiDate, currentDate)) {
        setUiDate({...currentDate});
      }
    } else {
      setUiDate({month: false, day: false});
    }
  }, [currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const days = [];
  if (uiDate.month !== false) {
    const daysInMonth = getDaysInMonth(uiDate.month);
    // Ta bort dag ifall den inte finns i månaden
    if (uiDate.day !== false && uiDate.day > daysInMonth) {
      setUiDate({...uiDate, day: false})
    }
    for(let i = 1; i <= daysInMonth; i++) {
      days.push({key: i, value: i});
    }
  }

  return <>
    <Select className="datepicker-select select-month" value={uiDate.month} onChange={updateMonth} list={MONTHS} label="Månad"
          placeholder={"Välj månad"}/>
    <Select className="datepicker-select select-day" value={uiDate.day} onChange={updateDay} list={days} label="Dag"
            placeholder={"Välj dag"} disabled={uiDate.month === false}/>
  </>
}

import {Button, H2, Subtitle1} from "@aurora/widgets-react";
import {useCurrentRoute, useSpecificEvent} from "../../state";
import React from "react";
import {Select, Card} from "@aurora/widgets-react";
import {WeatherHistoryGraph} from "./WeatherHistoryGraph";
import {DatePicker} from "../DatePicker";
import './WeatherHistory.css';
import {GraphInfoCard} from "../Graph/GraphInfoCard";
import {WEATHER_HISTORY_INFO} from "../../config";
import {MONTHS, scrollToDocumentId} from "../../utils";
import {ArrowUpOutline} from "@aurora/icons-react";


const SPECIFIC_EVENTS = [
  {key: 'season_lengths', value: 'Årstider'},
  {key: 'specific_date', value: 'Specifikt datum'},
  {key: 'christmas_eve', value: 'Julafton'},
  {key: 'new_years_eve', value: 'Nyårsafton'},
  {key: 'midsummer_eve', value: 'Midsommar'},
  {key: 'tropical_nights', value: 'Tropiska nätter' },
  {key: 'warm_summer_days', value: 'Högsommardagar'},
  {key: 'heatwaves', value: 'Värmeböljor'}
]

const SPECIFIC_EVENTS_PRECIPITATION = [
  {key: "precipitation_seasons", value: 'Årstider'},
  {key: 'precipitation_specific_date', value: 'Specifikt datum'},
  {key: 'precipitation_christmas', value: 'Julafton'},
  {key: 'precipitation_new_year', value: 'Nyårsafton'},
  {key: 'precipitation_midsummer_eve', value: 'Midsommar'},
  {key: 'precipitation_daysofdrought', value: 'Antal dagar utan nederbörd'},
  {key: 'precipitation_daysgt0', value: 'Antal dagar med nederbörd'},
  {key: 'precipitation_daysgt10', value: '≥ 10 mm nederbörd'},
  {key: 'precipitation_daysgt20', value: '≥ 20 mm nederbörd'},
]

const getLabelAndInfoText = (eventId, date, location) => {
  if(!eventId) {
    eventId = 'specific_date';
  }
  const specificEvent = WEATHER_HISTORY_INFO[eventId];

  let label = specificEvent.label;
  if(eventId.endsWith('specific_date') && date) {
    const monthLabel = MONTHS.find(month => month.key === date.month)
    if(monthLabel) {
      label = `${location} den ${date.day} ${monthLabel.value.toLowerCase()}`;
    }
  }

  return [label, specificEvent.infoText];
}

const WeatherHistory = ({parameter}) => {
  const [location] = useCurrentRoute();
  const [specificEvent, setSpecificEvent] = useSpecificEvent();
  const eventId = specificEvent.id;
  const [label, infoText] = getLabelAndInfoText(eventId, specificEvent.date, location);

  const onChangeSpecificEvent = v => {
    const eventId = v.target.value;
    if(eventId === 'christmas_eve') {
      setSpecificEvent({id: eventId, date: {month: 12, day: 24}});
    } else if(eventId === 'new_years_eve') {
      setSpecificEvent({id: eventId, date: {month: 12, day: 31}});
    } else {
      setSpecificEvent({id: eventId});
    }
  };

  const onChangeSpecificEventDate = async v => {
    await setSpecificEvent({ id: eventId, date: v});
  };

  const scrollUp = () => {
    scrollToDocumentId("title-bar", 0,
      () => document.getElementById("select-location-on-map").focus());
  };

  const events = parameter === "temperature" ? SPECIFIC_EVENTS : SPECIFIC_EVENTS_PRECIPITATION;

  return <div>
    <H2>Hur var vädret på mätstation {location === 'Sverige' ? 'Stockholm' : location}?</H2>
    {parameter === 'temperature' && (
        <Subtitle1 className="weather-history-introduction">
          Här kan du ta reda på om midsommarafton alltid är kylig och julafton
          mild, eller om vintern har blivit kortare och högsommardagarna fler!
        </Subtitle1>
      )}
    {parameter === 'precipitation' && (
        <Subtitle1 className="weather-history-introduction">
          Här kan du ta reda på om det brukar regna på midsommarafton och om det
          var mer nederbörd på julafton förr i tiden, eller kanske kika på hur
          ofta nederbörd har förekommit på din födelsedag!
        </Subtitle1>
      )}
    <div className="weatherhistory-toolbar">
      <Select className="specific-event-select" label="Specifik händelse/datum" placeholder="Välj händelse/datum" value={specificEvent.id} list={events} onChange={onChangeSpecificEvent}/>
      {eventId.endsWith('specific_date') && <DatePicker currentDate={specificEvent.date} setCurrentDate={onChangeSpecificEventDate}/>}
      <Button
        className="weatherhistory-scroll-up-btn"
        icon={<ArrowUpOutline />}
        shape={Button.shapes.round}
        variant={Button.variants.quaternary}
        onClick={scrollUp}
        label={"Välj ort"}
      />
    </div>
    <Card>
      <WeatherHistoryGraph event={specificEvent} label={label}>
        <GraphInfoCard label={label} infoText={infoText}/>
      </WeatherHistoryGraph>
      {/* <Row>
        <Col xs={8}>
          <GraphInfoCard label={label} infoText={infoText}/>
        </Col>
      </Row> */}
    </Card>
  </div>;
};

export default WeatherHistory;

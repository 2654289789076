import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP_LONG = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const TemperatureMonthlyAverageSeriesGraph = (props) => {
  const [stationId] = useCurrentStationIdAndYear()
  const [currentMonth] = useCurrentMonth();
  const uri = `temperature/month_series/${stationId}/${currentMonth}`;
  const bars = [
    { id: "T2mMonthlyAVG", fill: "#3B9CDF", name: `Månadsmedeltemperatur ${MONTH_MAP_LONG[currentMonth - 1]}`, enabled: true },
  ];
  const lines = [
    { id: "rolling", stroke: "#0C6BC4", name: `Trend - månadsmedeltemperatur`, enabled: false },
  ];
  return <GraphURI uri={uri} dataKey="year" bars={bars} lines={lines}
                {...props} />;
};

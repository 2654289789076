import React from "react";
import { useSelectedCard } from "../../state";
import { GraphInfoCard } from "./GraphInfoCard";
import {PrecipitationMonthlyRecordGraph, PrecipitationRecordGraph} from "./Precipitation/PrecipitationRecordGraph";
import {PrecipitationMonthlyAverageGraph} from "./Precipitation/PrecipitationMonthlyAverageGraph";
import {PrecipitationYearlyGraph} from "./Precipitation/PrecipitationYearlyGraph";
import {
  PrecipitationNormGraph,
  PrecipitationNormGraph1961_1990,
  PrecipitationNormGraph1991_2020
} from "./Precipitation/PrecipitationNormGraph";
import {TemperatureMonthlyRecordGraph, TemperatureRecordGraph} from "./Temperature/TemperatureRecordGraph";
import {TemperatureDailyAverageGraph} from "./Temperature/TemperatureDailyAverageGraph";
import {TemperatureMonthlyAverageGraph} from "./Temperature/TemperatureMonthlyAverageGraph";
import {TemperatureYearlyAverageGraph} from "./Temperature/TemperatureYearlyAverageGraph";
import {
  TemperatureNormGraph,
  TemperatureNormGraph1961_1990,
  TemperatureNormGraph1991_2020
} from "./Temperature/TemperatureNormGraph";
import {TemperatureMonthlyAverageSeriesGraph} from "./Temperature/TemperatureMonthlyAverageSeriesGraph";
import {
  TemperatureMonthlyNormGraph,
  TemperatureMonthlyNormGraph1961_1990, TemperatureMonthlyNormGraph1991_2020
} from "./Temperature/TemperatureMonthlyNormGraph";
import {
  PrecipitationMonthlyNormGraph,
  PrecipitationMonthlyNormGraph1961_1990, PrecipitationMonthlyNormGraph1991_2020
} from "./Precipitation/PrecipitationMonthlyNormGraph";
import {PrecipitationMonthlySeriesGraph} from "./Precipitation/PrecipitationMonthlySeriesGraph";
import {PrecipitationDailyAverageGraph} from "./Precipitation/PrecipitationDailyAverageGraph";


const GRAPH_MAP = {
  TemperatureRecordGraph: TemperatureRecordGraph,
  TemperatureMonthlyRecordGraph: TemperatureMonthlyRecordGraph,
  TemperatureDailyAverageGraph: TemperatureDailyAverageGraph,
  TemperatureMonthlyAverageGraph: TemperatureMonthlyAverageGraph,
  TemperatureYearlyAverageGraph: TemperatureYearlyAverageGraph,
  TemperatureNormGraph: TemperatureNormGraph,
  TemperatureNormGraph1961_1990: TemperatureNormGraph1961_1990,
  TemperatureNormGraph1991_2020: TemperatureNormGraph1991_2020,
  TemperatureMonthlyNormGraph: TemperatureMonthlyNormGraph,
  TemperatureMonthlyNormGraph1961_1990: TemperatureMonthlyNormGraph1961_1990,
  TemperatureMonthlyNormGraph1991_2020: TemperatureMonthlyNormGraph1991_2020,
  TemperatureMonthlyAverageSeriesGraph: TemperatureMonthlyAverageSeriesGraph,

  PrecipitationRecordGraph: PrecipitationRecordGraph,
  PrecipitationMonthlyRecordGraph: PrecipitationMonthlyRecordGraph,
  PrecipitationMonthlyAverageGraph: PrecipitationMonthlyAverageGraph,
  PrecipitationYearlyGraph: PrecipitationYearlyGraph,
  PrecipitationNormGraph: PrecipitationNormGraph,
  PrecipitationNormGraph1961_1990: PrecipitationNormGraph1961_1990,
  PrecipitationNormGraph1991_2020: PrecipitationNormGraph1991_2020,
  PrecipitationMonthlyNormGraph: PrecipitationMonthlyNormGraph,
  PrecipitationMonthlyNormGraph1961_1990: PrecipitationMonthlyNormGraph1961_1990,
  PrecipitationMonthlyNormGraph1991_2020: PrecipitationMonthlyNormGraph1991_2020,
  PrecipitationMonthlySeriesGraph: PrecipitationMonthlySeriesGraph,
  PrecipitationDailyAverageGraph: PrecipitationDailyAverageGraph
};

export const CurrentGraph = () => {
  const [selectedCard] = useSelectedCard();
  const { graph, graphLabel, infoText } = selectedCard;
  const Graph = GRAPH_MAP[graph];

  return (
    <div>
      <Graph scrollUpButton={false} label={graphLabel}>
        <GraphInfoCard label={graphLabel} infoText={infoText} />
      </Graph>
    </div>
  );
};

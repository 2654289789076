import {H3} from "@aurora/widgets-react";
import './GraphInfoCard.css';

export const GraphInfoCard = ({label, infoText}) => {
  console.log("text is", label, infoText)
  return <div className="graph-info-card">
    <H3>{label}</H3>
    <div className="graph-info-card-text">
    {infoText ? infoText :
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua.`}
    </div>
  </div>
}

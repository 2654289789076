import React from "react";
import { MapContainer, WMSTileLayer, Marker, Tooltip } from "react-leaflet";
import {useLocation, useStations} from "../../state";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import ZoomControl from "./ZoomControl";
import { scrollToDocumentId } from "../../utils";
import "leaflet/dist/leaflet.css";
import "./SelectLocationMap.css";

const MAP_OPTIONS = {
  center: [63, 17],
  zoom: 5,
  minZoom: 5,
  maxZoom: 8,
  zoomControl: false,
  attributionControl: false,
  crs: L.CRS.EPSG900913,
};

const BACKGROUND_LAYER_OPTIONS = {
  url: "//wts{s}.smhi.se/mapproxy/service",
  subdomains: "1234",
  layers: "osm-aurora-subtle-base-map-light-background",
  format: "image/png",
};

const CITIES_OVERLAY_OPTIONS = {
  url: "//wts{s}.smhi.se/mapproxy/service",
  subdomains: "1234",
  layers: "osm-aurora-subtle-base-map-light-labels",
  format: "image/png",
  transparent: true,
};

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowSize: [41, 41],
});

const SelectLocationMap = () => {
  const [stations] = useStations();
  const [locationId, setLocationById] = useLocation();

  const setStation = (event, locationId) => {
    setLocationById(locationId);
    scrollToDocumentId("title-bar", 0);
  };

  return (
    <div className="map-container">
      <MapContainer className="map" {...MAP_OPTIONS} tap={false}>
        <WMSTileLayer {...BACKGROUND_LAYER_OPTIONS} />
        <WMSTileLayer {...CITIES_OVERLAY_OPTIONS} />
        <ZoomControl min={MAP_OPTIONS.minZoom} max={MAP_OPTIONS.maxZoom} />
        {stations.map((station) => (
          <Marker
            key={`select-location-marker-${station.number}`}
            icon={DefaultIcon}
            position={[station.lat, station.lng]}
            keyboard={false}
            eventHandlers={{
              click: (e) => setStation(e, station.number)
            }}
          >
            <Tooltip
              className={
                station.number === locationId ? "active-location-tooltip" : ""
              }
              permanent={station.number === locationId}
              direction="bottom"
            >
              {station.name}
            </Tooltip>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default SelectLocationMap;

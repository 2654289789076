import {Table, TableHead, TableHeadCell, TableBody, TableRow, TableCell} from "@aurora/widgets-react"
import { Subtitle1 } from "@aurora/widgets-react";
import { useScreenMode } from "../../state";
import LoadingIndicator from "../LoadingIndicator";
import ResponsiveWrapper from "../ResponsiveWrapper";
import './GraphTable.css';

const TableCellIcon = ({value, def, showIcon, children}) => {
  const {stroke, fill, strokeDasharray} = def;
  return <TableCell>
    <div className="graph-table-td-icon">
      {showIcon && <svg width={24} height={24} viewBox={"0 0 50 50"}>
      {(Array.isArray(value) || stroke === undefined) ?
        <line x1={10} y1={25} x2={40} y2={25} stroke={fill} strokeWidth={30}/> :
        <line x1={10} y1={25} x2={40} y2={25} stroke={stroke} strokeWidth={5} strokeDasharray={strokeDasharray}/>}
      </svg>}
      {children}
    </div>
  </TableCell>
}

const formatNumber = v => Number.isInteger(v) ? v : v.toFixed(1);
const formatTableValue = (value, meta) => {
  if (value === undefined || value === 'null') { return '-'; }
  if (typeof(value) === "string") { return value; }
  let unit = (meta.unit && meta.unit !== '') ? meta.unit : '°';
  const v = Array.isArray(value) ? `${formatNumber(value[0])} – ${formatNumber(value[1])}` : formatNumber(value);
  return `${v} ${unit}`
}

export const GraphTable =
  ({data, dataKey = "date", tickFormatter=undefined,
    tables = [],
    lines = [], bars = [], areas = [], children, event, label,
     ...props}) => {

  const { isMobile } = useScreenMode();

  const eventDateNotSet = event !== undefined && event.id.endsWith('specific_date') && !event.date;

  let dataKeyLabel = 'Datum';
  if (dataKey === 'month') {
    dataKeyLabel = 'Månad';
  }

  return <ResponsiveWrapper className="graph-table-holder" height={500}>
    {data && !eventDateNotSet && (<Table className={"graph-table"}>
      <caption>{label}</caption>
        <TableHead>
          <TableHeadCell>{dataKeyLabel}</TableHeadCell>
          {bars.map((bar, i) => <TableHeadCell key={i}>{bar.name}</TableHeadCell>)}
          {lines.map((line, i) => <TableHeadCell key={i}>{line.name}</TableHeadCell>)}
          {areas.map((area, i) => <TableHeadCell key={i}>{area.name}</TableHeadCell>)}
          {tables.map((table, i) => <TableHeadCell key={i}>{table.name}</TableHeadCell>)}
        </TableHead>
        <TableBody >
          {data.map((row, i) =>
            <TableRow key={i}>
              <TableCell>{tickFormatter ? tickFormatter(row[dataKey]) : row[dataKey]}</TableCell>
              {bars.map((bar, i) => <TableCellIcon key={i} value={row[bar.id]} def={bar} showIcon={!isMobile}>{formatTableValue(row[bar.id], bar)}</TableCellIcon>)}
              {lines.map((line, i) => <TableCellIcon key={i} def={line} showIcon={!isMobile}>{formatTableValue(row[line.id], line)}</TableCellIcon>)}
              {areas.map((area, i) => <TableCellIcon key={i} value={row[area.id]} def={area} showIcon={!isMobile}>{formatTableValue(row[area.id], area)}</TableCellIcon>)}
              {tables.map((table, i) => <TableCellIcon key={i} value={row[table.id]} def={table} showIcon={!isMobile}>{formatTableValue(row[table.id], table)}</TableCellIcon>)}
            </TableRow>
          )}
        </TableBody>
      </Table>)}
      {eventDateNotSet && <div className="graph-specific-date-notice"><Subtitle1>Välj ett datum ovan</Subtitle1></div>}
      {!data && !eventDateNotSet && <LoadingIndicator className="graph-table-loading" />}
    </ResponsiveWrapper>
}

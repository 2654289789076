import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const TemperatureRecordGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `temperature/charts/${stationId}/${currentYear}`

  const YEARLY_LINES = [
    {
      id: `T2mMIN_min_${currentYear}`,
      stroke: "#1B2B36",
      name: `Dygnsmin: ${currentYear}`,
      strokeDasharray: "2 2",
      enabled: true,
    },
    {
      id: `T2mMAX_max_${currentYear}`,
      stroke: "#1B2B36",
      name: `Dygnsmax: ${currentYear}`,
      strokeDasharray: "2 2",
      enabled: true,
    },
  ];

  const GRAPH_AREAS = [
    {
      id: "T2m_extreme_full_period",
      name: `Temperaturrekord`,
      fill: "#99D6FF",
      gradient: {
        top: "#F82B37",
        bottom: "#3B9CDF"
      },
      enabled: true,
    }
  ];

  return (
    <GraphURI
      uri={uri}
      lines={YEARLY_LINES}
      areas={GRAPH_AREAS}
      ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
      {...props}
    />
  );
};

export const TemperatureMonthlyRecordGraph = (props) => {
  const [currentMonth] = useCurrentMonth();
  const filterData = data => data.filter((d) => currentMonth === parseInt(d.date.split("/")[1]));
  return <TemperatureRecordGraph month={currentMonth} {...props}
                                 ticks={undefined} filterData={filterData}/>;
};

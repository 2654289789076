import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP_LONG = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const TemperatureDailyAverageGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `temperature/daily_temps_now/${stationId}`

  const GRAPH_BARS = [
    {
      id: `T2mAVG_${currentYear - 1}`,
      fill: "#3B9CDF",
      name: `Dygnsmedel ${MONTH_MAP_LONG[currentMonth - 1]} ${currentYear - 1}`,
      enabled: true,
    },
    {
      id: `T2mAVG_${currentYear}`,
      fill: "#99D6FF",
      name: `Dygnsmedel ${MONTH_MAP_LONG[currentMonth - 1]} ${currentYear}`,
      enabled: false,
    },
  ];
  return (
    <GraphURI uri={uri} dataKey="date" bars={GRAPH_BARS} {...props}
      filterData={data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]))}
    />
  );
};

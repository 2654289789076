import {Graph} from "../Graph";
import {Table, TableHead, TableHeadCell, TableBody, TableRow, TableCell} from "@aurora/widgets-react";
import {useCurrentStationIdAndYear, useScreenMode} from "../../state";
import React from "react";
import {GraphURI} from "../Graph/GraphURI";

const GRAPH_CONFIG = {
  'season_lengths': {
    uri: ({stationId}) => `temperature/season_lengths/${stationId}`,
    yAxisLabel: 'Antal dygn (Trend)',
    yAxisPadding: {top: 50, bottom: 50},
    lines: [
      // {id: "summer_length", stroke: "#F0D700", name: "Sommardagar", unit: 'dagar'},
      {id: "summer_length_rolling", stroke: "#C9B200", name: "Trendlinje Sommar", unit: 'dygn', enabled: true},
      // {id: "winter_length", stroke: "#99D6FF", name: "Vinterdagar", unit: 'dagar'},
      {id: "winter_length_rolling", stroke: "#3B9CDF", name: "Trendlinje Vinter", unit: 'dygn', enabled: true},
      // {id: "autumn_length", stroke: "#F82B37", name: "Höstdagar", unit: 'dagar'},
      {id: "autumn_length_rolling", stroke: "#F82B37", name: "Trendlinje Höst", unit: 'dygn', enabled: true},
      // {id: "spring_length", stroke: "#72CA34", name: "Vårdagar", unit: 'dagar'},
      {id: "spring_length_rolling", stroke: "#3DA000", name: "Trendlinje Vår", unit: 'dygn', enabled: true},
    ]
  },
  'tropical_nights': {
    uri: ({stationId}) => `temperature/tropical_nights/${stationId}`,
    bars: [
      {id: "number_of_tropical_nights", fill: "#F82B37", name: "Antal tropiska nätter", unit: 'dygn', enabled: true}
    ]
  },
  'warm_summer_days': {
    uri: ({stationId}) => `temperature/warm_summer_days/${stationId}`,
    bars: [
      {id: "number_of_warm_summer_days", fill: "#F82B37", name: "Antal högsommardagar", unit: 'dygn', enabled: true}
    ]
  },
  'heatwaves': {
    uri: ({stationId}) => `temperature/heatwaves/${stationId}`,
    yAxisLabel: 'Antal / Antal Dygn',
    bars: [
      {id: "number_of_heatwaves", fill: "#F82B37", name: "Antal värmeböljor", unit: ' ', enabled: true},
      {id: "max_length_of_heatwave", fill: "#930005", name: "Max längd värmebölja", unit: 'dygn'},
      // {id: "avg_length_of_heatwave", fill: "#3d8937", name: "Medel längd värmebölja", unit: 'dagar'}
    ],
    // lines: [
    //   {id: "max_length_of_heatwave", stroke: "#930005", name: "Max längd värmebölja", unit: 'dagar'},
    //   {id: "avg_length_of_heatwave", stroke: "#3d8937", name: "Medel längd värmebölja", unit: 'dagar'}
    // ]
  },

  'specific_date': {
    uri: ({stationId, date}) => `temperature/day_series/${stationId}/${date.month}/${date.day}`,
    yAxisLabel: '°C',
    dataKey: 'date',
    bars: [
      {id: 'T2mAVG', fill: "#3B9CDF", name: 'Medelvärde', enabled: true},
      {id: 'T2mMAX', fill: "#F82B37", name: 'Högsta temperatur'},
      {id: 'T2mMIN', fill: "#1B2B36", name: 'Lägsta temperatur'},
    ]
  },

  'midsummer_eve': {
    uri: ({stationId}) => `temperature/midsummer_eve/${stationId}`,
    yAxisLabel: '°C',
    dataKey: 'date',
    bars: [
      {id: 'T2mAVG', fill: "#3B9CDF", name: 'Medelvärde', enabled: true},
      {id: 'T2mMAX', fill: "#F82B37", name: 'Högsta temperatur'},
      {id: 'T2mMIN', fill: "#1B2B36", name: 'Lägsta temperatur'},
    ]
  },

  'precipitation_seasons': {
    uri: ({stationId}) => `precipitation/seasonal_precipitation/${stationId}`,
    yAxisLabel: 'mm',
    dataKey: 'year',
    showUncertainty: true,
    lines: [
      {id: 'spring_trend', stroke: "#3DA000", name: 'Trendlinje Vår', enabled: true, unit: "mm"},
      // {id: 'spring', fill: "#3DA000", name: 'Vår', enabled: true, unit: "mm},
      {id: "summer_trend", stroke: "#C9B200", name: "Trendlinje Sommar", enabled: true, unit: "mm"},
      // {id: "summer", fill: "#C9B200", name: "Sommar", enabled: true, unit: "mm},
      {id: "autumn_trend", stroke: "#F82B37", name: "Trendlinje Höst", enabled: true, unit: "mm"},
      // {id: "autumn", fill: "#F82B37", name: "Höst", enabled: true, unit: "mm},
      {id: "winter_trend", stroke: "#3B9CDF", name: "Trendlinje Vinter", enabled: true, unit: "mm"},
      // {id: "winter", fill: "#3B9CDF", name: "Vinter", enabled: true, unit: "mm},
    ]
  },

  'precipitation_midsummer_eve': {
    uri: ({stationId}) => `precipitation/midsummer_eve/${stationId}`,
    yAxisLabel: 'mm',
    dataKey: 'date',
    showUncertainty: true,
    bars: [
      {id: 'Dygnsnederbörd', fill: "#74BCED", name: 'Dygnsnederbörd', enabled: true, unit: "mm"}
    ]
  },

  'precipitation_christmas': {
    uri: ({stationId}) => `precipitation/day_series/${stationId}/12/24`,
    yAxisLabel: 'mm',
    dataKey: 'date',
    showUncertainty: true,
    bars: [
      {id: 'Dygnsnederbörd', fill: "#74BCED", name: 'Dygnsnederbörd', enabled: true, unit: "mm"}
    ]
  },

  'precipitation_new_year': {
    uri: ({stationId}) => `precipitation/day_series/${stationId}/12/31`,
    yAxisLabel: 'mm',
    dataKey: 'date',
    showUncertainty: true,
    bars: [
      {id: 'Dygnsnederbörd', fill: "#74BCED", name: 'Dygnsnederbörd', enabled: true, unit: "mm"}
    ]
  },

  'precipitation_daysofdrought': {
    uri: ({stationId}) => `precipitation/daysofdrought/${stationId}`,
    yAxisLabel: 'Antal dagar',
    dataKey: 'year',
    showUncertainty: true,
    bars: [
      {id: 'antal_dagar', fill: "#74BCED", name: 'Dagar utan nederbörd', enabled: true, unit: ' '},
      {id: 'antal_dagar_i_rad', fill: "#002863", name: 'Max antal dagar i rad utan nederbörd', enabled: false, unit: ' '}
    ],
    fixedDomain: true,
    updateFixedDomain: true
  },

  'precipitation_daysgt0': {
    uri: ({stationId}) => `precipitation/daysgt/${stationId}/0`,
    yAxisLabel: 'Antal dagar',
    dataKey: 'year',
    showUncertainty: true,
    bars: [
      {id: 'antal_dagar', fill: "#74BCED", name: 'Dagar med nederbörd', enabled: true, unit: ' '},
      {id: 'antal_dagar_i_rad', fill: "#002863", name: 'Max antal dagar i rad med nederbörd', enabled: false, unit: ' '}
    ],
    fixedDomain: true,
    updateFixedDomain: true
  },

  'precipitation_daysgt10': {
    uri: ({stationId}) => `precipitation/daysgt/${stationId}/10`,
    yAxisLabel: 'Antal dagar',
    dataKey: 'year',
    showUncertainty: true,
    bars: [
      {id: 'antal_dagar', fill: "#74BCED", name: 'Dagar med mer än 10 mm nederbörd', enabled: true, unit: ' '},
      {id: 'antal_dagar_i_rad', fill: "#002863", name: 'Max antal dagar i rad med mer än 10 mm nederbörd', enabled: false, unit: ' '}
    ],
    fixedDomain: true,
    updateFixedDomain: true
  },

  'precipitation_daysgt20': {
    uri: ({stationId}) => `precipitation/daysgt/${stationId}/20`,
    yAxisLabel: 'Antal dagar',
    dataKey: 'year',
    showUncertainty: true,
    bars: [
      {id: 'antal_dagar', fill: "#74BCED", name: 'Dagar med mer än 20 mm nederbörd', enabled: true, unit: ' '},
      {id: 'antal_dagar_i_rad', fill: "#002863", name: 'Max antal dagar i rad med mer än 20 mm nederbörd', enabled: false, unit: ' '}
    ],
    fixedDomain: true,
    updateFixedDomain: true
  },

  'precipitation_specific_date': {
    uri: ({stationId, date}) => `precipitation/day_series/${stationId}/${date.month}/${date.day}`,
    yAxisLabel: 'mm',
    dataKey: 'year',
    showUncertainty: true,
    bars: [
      {id: 'Dygnsnederbörd', fill: "#74BCED", name: 'Dygnsnederbörd', enabled: true, unit: 'mm'},
    ]
  },

}

// convert 1979-09-27 -> 1979
const tickFormatOnlyYear = v => v !== undefined && v.slice ? v.slice(0, 4) : v

const renderToolTipSeasonLengths = ({payload, label}) => {
  const { isMobile } = useScreenMode(); // eslint-disable-line react-hooks/rules-of-hooks

  const renderIcon = (stroke) =>
    <svg width={20} height={20} viewBox={"0 0 50 50"}>
      <line x1={0} y1={25} x2={50} y2={25} stroke={stroke} strokeWidth={5}/>
    </svg>
  const renderValue = value => value === 'null' ? '-' : value
  const SEASON_LABELS = {
    spring: <div className="season-start-table-season">{renderIcon("#72CA34")} Vår</div>,
    summer: <div className="season-start-table-season">{renderIcon("#F0D700")} Sommar</div>,
    autumn: <div className="season-start-table-season">{renderIcon("#F82B37")} Höst</div>,
    winter: <div className="season-start-table-season">{renderIcon("#99D6FF")} Vinter</div>
  };

  if (!payload || !payload[0]) {
    return null;
  }
  const filterSeasons = {};
  payload.forEach(d => filterSeasons[d.dataKey.split("_")[0]] = true);

  const data = payload[0].payload;
  let tableData = [];
  ["spring", "summer", "autumn", "winter"].forEach(s => {
    if (!filterSeasons[s]) {
      return;
    }
    tableData.push({
      label: SEASON_LABELS[s],
      startDate: renderValue(data[s + "_start_date"]),
      length: renderValue(data[s + "_length"]),
      rolling: data[s + "_length_rolling"]
    })
  });

  tableData.sort((a, b) => b.rolling - a.rolling);

  return (
    <div className="graph-custom-tooltip season-start-tooltip">
      <p className="graph-custom-tooltip-label">{label}/{label+1}</p>
      <Table className="season-start-table">
        <TableHead>
          <TableHeadCell>Årstid</TableHeadCell>
          <TableHeadCell>Trend</TableHeadCell>
          {!isMobile && (
            <>
              <TableHeadCell>Säsongsstart</TableHeadCell>
              <TableHeadCell>Dagar</TableHeadCell>
            </>
          )}
        </TableHead>
        <TableBody>
          {tableData.map(({ label, startDate, length, rolling }, i) => (
            <TableRow key={i}>
              <TableCell>{label}</TableCell>
              <TableCell>{rolling === 'null' ? '-' : rolling.toFixed(1)}</TableCell>
              {!isMobile && (
                <>
                  <TableCell>{startDate}</TableCell>
                  <TableCell>{length}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export const WeatherHistoryGraph = ({event, label, children}) => {
  const eventId = event.id;
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  let graphConfig = GRAPH_CONFIG[eventId]
  if (!graphConfig) {
    graphConfig = GRAPH_CONFIG['specific_date'];
  }

  const uri = graphConfig.uri && graphConfig.uri({stationId, currentYear, ...event});
  const bars = graphConfig.bars || [];
  const lines = graphConfig.lines || [];
  const yAxisLabel = graphConfig.yAxisLabel || 'Antal dygn';

  let renderTooltip = false;
  let tooltipLabelFormatter = id => id;

  let filterData = data => data;

  if (eventId === 'season_lengths') {
    renderTooltip = renderToolTipSeasonLengths;
    // Ta bort innevarande år
    filterData = data => {
      const year = new Date().getFullYear();
      return data ? data.filter(d => d.year !== year) : [];
    }
  }

  return <GraphURI uri={uri} dataKey={graphConfig.dataKey || 'year'} bars={bars} lines={lines}
                   label={label}
                   yAxisLabel={yAxisLabel} tickFormatter={tickFormatOnlyYear}
                   children={children} renderTooltip={renderTooltip} yAxisPadding={graphConfig.yAxisPadding}
                   tooltipLabelFormatter={tooltipLabelFormatter}
                   showUncertainty={graphConfig.showUncertainty || false}
                   event={event} filterData={filterData}
                   sortTooltipValues={eventId === 'precipitation_seasons'}
  />;
}

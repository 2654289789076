import {MoreOutline} from "@aurora/icons-react";
import './LoadingIndicator.css';

const LoadingIndicator = ({className = ''}) => {
  return <div className={className}>
    <div className="loading-indicator">
      <MoreOutline/>
      <span aria-live='polite'>Laddar</span>
    </div>
  </div>;
}

export default LoadingIndicator;

import { CONTEXT_PATH } from "../utils";

export async function getAvailableStations(parameter = 'temperature') {
  const response = await fetch(`${CONTEXT_PATH}api/${parameter}/stations_available`);
  if (!response.ok) {
    throw new Error('getAvailableStations failed');
  }
  const stations = await response.json();
  return stations.sort((a, b) => a.name > b.name ? 1: -1);
}

import {AuroraRoot, Button} from "@aurora/widgets-react";
import {CloseOutline} from "@aurora/icons-react";
import TitleBar from "./components/TitleBar";
import React from "react";
import Cards from "./components/Cards";
import {
  useRoute,
  useResponsiveApp,
  useUpdateAppData,
  useIsLoading,
  useErrorMessage,
  useCurrentSelectedCard
} from "./state";
import LoadingIndicator from "./components/LoadingIndicator";
import Carousel from "./components/Carousel";
import WeatherHistory from "./components/WeatherHistory";
import {CurrentGraph} from "./components/Graph/CurrentGraph";
import {ErrorMessage} from "./components/ErrorMessage";
import {useEffect} from "react";
import {Card} from "@aurora/widgets-react";
import {scrollToDocumentId} from "./utils";

function App({station = "Stockholm", parameter="temperature"}) {
  const [loading] = useIsLoading();
  const [errorMessage] = useErrorMessage();
  const [currentCard, setCurrentCard] = useCurrentSelectedCard();
  const [route, setRoute] = useRoute();

  const scrollUp = () => {
    const targetCard = currentCard;
    scrollToDocumentId("cards-holder", 0,
      () => document.getElementById("card" + targetCard).focus());
    setCurrentCard(false);

  };
  useEffect(() => {
    setRoute({station, parameter});
  }, [station, parameter, setRoute])
  useUpdateAppData();
  useResponsiveApp();

  return (
    <AuroraRoot>
      {errorMessage && <ErrorMessage message={errorMessage}/>}
      <div className="hvv-container">
        <TitleBar/>
        {!loading && (
          <main>
            <Cards/>
            {currentCard !== false &&
            <Card id="diagram-och-tabell">

              <div className="graph--scroll-up-btn-container">
                <Button
                  className="graph--scroll-up-btn"
                  label={"Stäng diagram"}
                  icon={<CloseOutline/>}
                  iconOnly
                  shape={Button.shapes.round}
                  variant={Button.variants.quaternary}
                  onClick={scrollUp}
                />
              </div>

              <Carousel/>
              <CurrentGraph/>
            </Card>}
            <WeatherHistory parameter={route.parameter}/>
          </main>
        )}
        {loading && <LoadingIndicator className="loading-app"/>}
      </div>
    </AuroraRoot>
  );
};

export default App;

import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
const MONTH_MAP_LONG = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September',
  'Oktober', 'November', 'December'];
const formatMonth = m => MONTH_MAP[m-1];
const formatMonthLong = m => MONTH_MAP_LONG[m-1];

export const PrecipitationMonthlyAverageGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `precipitation/monthly_precipitation/${stationId}`
  const GRAPH_BARS = [
    {id: `monthly_precipitation_${currentYear - 1}`, fill: "#74BCED", name: `Månadsnederbörd ${currentYear - 1}`, enabled: true, unit: "mm"},
    {id: `monthly_precipitation_${currentYear}`, fill: "#0C6BC4", name: `Månadsnederbörd ${currentYear}`, unit: "mm"},
  ];
  return (
    <GraphURI
      uri={uri}
      dataKey="month"
      bars={GRAPH_BARS}
      tickFormatter={formatMonth}
      tooltipLabelFormatter={formatMonthLong}
      yAxisLabel="mm"
      {...props}
    />
  );
}

import {GraphURI} from "../GraphURI";
import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";

export const TemperatureMonthlyNormGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `temperature/charts/${stationId}/${currentYear}`;

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1

  const lines = [
    {
      id: "T2mAVG_std_1991-2020",
      stroke: "#F82B37",
      name: "Normalvärden: 1991 – 2020",
      enabled: true,
    },
    {
      id: "T2mAVG_std_1961-1990",
      stroke: "#3B9CDF",
      name: "Normalvärden: 1961 – 1990",
      enabled: true,
    },
    {
      id: `T2mAVG_${compareYear}`,
      stroke: "#1B2B36",
      name: `Dygnsmedel: ${compareYear}`,
    }
  ]
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));
  return <GraphURI uri={uri} lines={lines} yAxisPadding={{ bottom: 30}}
                   filterData={filterData}
                   {...props}/>;
};

export const TemperatureMonthlyNormGraph1961_1990 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `temperature/charts/${stationId}/${currentYear}`;

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1

  const lines = [
    {
      id: "T2mAVG_std_1961-1990",
      stroke: "#3B9CDF",
      name: "Normalvärden: 1961 – 1990",
      enabled: true,
    },
    {
      id: `T2mAVG_${compareYear}`,
      stroke: "#1B2B36",
      name: `Dygnsmedel: ${compareYear}`,
    }
  ]
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));
  return <GraphURI uri={uri} lines={lines} yAxisPadding={{ bottom: 30}}
                   filterData={filterData}
                   {...props}/>;
};

export const TemperatureMonthlyNormGraph1991_2020 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const [currentMonth] = useCurrentMonth();
  const uri = `temperature/charts/${stationId}/${currentYear}`;

  const todaysMonth = new Date().getMonth() + 1; // addera 1 eftersom getMonth börjar på 0 och useCurrentMonth börjar på 1
  const compareYear = todaysMonth > currentMonth ? currentYear : currentYear - 1
  
  const lines = [
    {
      id: "T2mAVG_std_1991-2020",
      stroke: "#F82B37",
      name: "Normalvärden: 1991 – 2020",
      enabled: true,
    },
    {
      id: `T2mAVG_${compareYear}`,
      stroke: "#1B2B36",
      name: `Dygnsmedel: ${compareYear}`,
    }
  ]
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));
  return <GraphURI uri={uri} lines={lines} yAxisPadding={{ bottom: 30}}
                   filterData={filterData}
                   {...props}/>;
};

import {Graph} from "./index";
import {useGraph} from "../../state";
import LoadingIndicator from "../LoadingIndicator";

export const GraphURI = ({uri, filterData=false, ...props}) => {
  const [graph] = useGraph(uri);

  if (graph === null) {
    return <LoadingIndicator className={"graph-loader"}/>
  }
  // console.log("graphuri with ", uri,  graph);

  let {data} = graph;

  if (filterData) {
    data = filterData(data);
  }

  return <Graph data={data} {...props}/>
}

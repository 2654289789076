import {AuroraRoot, Card, H3} from "@aurora/widgets-react";

export const ErrorMessage = ({message}) => {
  return <AuroraRoot>
    <div className="hvv-container">
      <Card>
        <H3>Fel: {message}</H3>
      </Card>
    </div>
  </AuroraRoot>;
}

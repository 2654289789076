import React, { useState, useEffect } from "react";
import { Button, H1, H2, Subtitle1 } from "@aurora/widgets-react";
import "./TitleBar.css";
import {useCurrentRoute, useIsLoading, useScreenMode, useParameter} from "../../state";
import SelectLocation from "../SelectLocation";
import SelectLocationMap from "../SelectLocationMap";
import {MapOutline} from "@aurora/icons-react";

const TitleBar = () => {
  const [station] = useCurrentRoute();
  const [loading] = useIsLoading();
  const [showLocationMap, setShowLocationMap] = useState(false);
  const [parameter] = useParameter();
  const { isMobile } = useScreenMode();

  useEffect(() => {
    setShowLocationMap(false);
  }, [station]);

  const toggleLocationMap = () => setShowLocationMap((show) => !show);

  return (
    <>
      <header className="title-bar" id="title-bar">
        <div className="title-bar-title">
          <H1>Hur var vädret?</H1>
        </div>
        <div className="title-bar-buttons">
          <div>
            {showLocationMap ?
              <Button
                variant={Button.variants.secondary}
                id="select-location-on-map"
                label={"Stäng karta"}
                onClick={toggleLocationMap}
                selected={showLocationMap}
                disabled={loading}
              /> :
              <Button
                variant={Button.variants.secondary}
                id="select-location-on-map"
                icon={<MapOutline/>}
                iconOnly={isMobile}
                label={"Välj ort på karta"}
                onClick={toggleLocationMap}
                selected={showLocationMap}
                disabled={loading}
              />
            }
            <SelectLocation />
          </div>
        </div>
      </header>
      <H2 className="location-title">{station}</H2>
      {parameter === "temperature" && <Subtitle1 className="location-introduction-text">
        Här kan du se hur vädret har varit en viss dag, månad eller år. Du kan
        även kika på om temperaturen har förändrats över tid, på vald plats.
      </Subtitle1>}
      {parameter === "precipitation" && <Subtitle1 className="location-introduction-text">
        Här kan du undersöka nederbördsmängden per dygn, månad eller år. Nederbörd mäts i millimeter (mm), 1 mm motsvarar 1 liter per kvadratmeter. En cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.
      </Subtitle1>}

      {showLocationMap && <SelectLocationMap />}
    </>
  );
};

export default TitleBar;

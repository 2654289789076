import {Select} from "@aurora/widgets-react";
import React, {useState} from "react";
import {useIsLoading, useLocation, useStations} from "../../state";

const SelectLocation = () => {
  const [stations] = useStations('stations');
  const [loading] = useIsLoading();
  const [ref, setRef] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [, setLocationById] = useLocation();
  const stationsList = stations.map(s => ({key: s.number, value: s.name}));

  const setStation = v => {
    const locationId = v.target.value;
    setLocationById(locationId);
  };

  const updateRef = ref => {
    // Hide label since aurora Select don't have that option.
    // The label will be referenced with aria-attribute.
    if (ref) {
      const labels = ref.getElementsByTagName("label");
      if (labels.length > 0) { labels[0].hidden = true; }
    }
    setRef(ref);
  }

  // Handle shortcut to go to locations starting with the pressed letter.
  // Need to find elements in Aurora Select component
  const keyPress = event => {
    if(ref === null) { return; }
    try {
      const elements = ref.getElementsByTagName("li");
      const key = event.key.toUpperCase();
      const str = searchString + key;
      setSearchString(str);
      for (let e of elements) {
        const name = e.firstChild.firstChild.firstChild.textContent
        if (name.toUpperCase().startsWith(str)) {
          // Trigger mousemove event to focus the element in the list.
          const moEvent = new MouseEvent('mousemove', {
            view: window,
            bubbles: true,
            cancelable: true
          })
          e.dispatchEvent(moEvent);
          e.parentNode.scrollTop = e.offsetTop;
          return;
        }
      }
    } catch(exp) {
    }
  }
  const focus = event => setSearchString("");

  return <Select onKeyPress={keyPress} onFocus={focus} ref={updateRef} label="Välj ort" className="title-bar-select" placeholder="Välj ort" value={false} list={stationsList} onChange={setStation} disabled={loading} />;
}

export default SelectLocation;

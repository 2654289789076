import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
const MONTH_MAP_LONG = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September',
    'Oktober', 'November', 'December'];
const formatMonth = m => MONTH_MAP[m-1];
const formatMonthLong = m => MONTH_MAP_LONG[m-1];

export const PrecipitationNormGraph = (props) => {
    const [stationId, currentYear] = useCurrentStationIdAndYear();
    const uri = `precipitation/monthly_precipitation/${stationId}`
    const GRAPH_BARS = [
        {id: `monthly_average_1961-1990`, fill: "#74BCED", name: `Normalvärde 1961-1990`, enabled: true, unit: "mm"},
        {id: `monthly_average_1991-2020`, fill: "#0C6BC4", name: `Normalvärde 1991-2020`, enabled: true, unit: "mm"},
        {id: `monthly_precipitation_${currentYear - 1}`, fill: "#002863", name: `Månadsnederbörd ${currentYear - 1}`, unit: "mm"},
    ];
    return <GraphURI
        uri={uri}
        dataKey={'month'}
        bars={GRAPH_BARS}
        tickFormatter={formatMonth}
        tooltipLabelFormatter={formatMonthLong}
        yAxisLabel='mm'
        {...props}
    />
};

export const PrecipitationNormGraph1961_1990 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `precipitation/monthly_precipitation/${stationId}`
  const GRAPH_BARS = [
    {id: `monthly_average_1961-1990`, fill: "#74BCED", name: `Normalvärde 1961-1990`, enabled: true, unit: "mm"},
    {id: `monthly_precipitation_${currentYear - 1}`, fill: "#002863", name: `Månadsnederbörd ${currentYear - 1}`, unit: "mm"},
  ];
  return <GraphURI
    uri={uri}
    dataKey={'month'}
    bars={GRAPH_BARS}
    tickFormatter={formatMonth}
    tooltipLabelFormatter={formatMonthLong}
    yAxisLabel='mm'
    {...props}
  />
};

export const PrecipitationNormGraph1991_2020 = (props) => {
    const [stationId, currentYear] = useCurrentStationIdAndYear();
    const uri = `precipitation/monthly_precipitation/${stationId}`
    const GRAPH_BARS = [
      {id: `monthly_average_1991-2020`, fill: "#0C6BC4", name: `Normalvärde 1991-2020`, enabled: true, unit: "mm"},
      {id: `monthly_precipitation_${currentYear - 1}`, fill: "#002863", name: `Månadsnederbörd ${currentYear - 1}`, unit: "mm"},
    ];
    return <GraphURI
        uri={uri}
        dataKey={'month'}
        bars={GRAPH_BARS}
        tickFormatter={formatMonth}
        tooltipLabelFormatter={formatMonthLong}
        yAxisLabel='mm'
        {...props}
    />
};

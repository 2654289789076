import React from "react";
import PropTypes from 'prop-types';
import "./ResponsiveWrapper.css";

const ResponsiveWrapper = ({ className, height, children }) => {
  // Wraps children in "relative" and "absolute" parents with a fixed height to fix problems with responsive width 
  return (
    <div
      className={`responsive-wrapper ${className}`}
      style={{
        paddingBottom: height,
      }}
    >
      <div className="responsive-wrapper--inner">{children}</div>
    </div>
  );
};

ResponsiveWrapper.defaultProps = {
  className: "",
};

ResponsiveWrapper.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  children: PropTypes.node
}

export default ResponsiveWrapper;

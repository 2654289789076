import {createGlobalState} from 'react-hooks-global-state';
import {getAvailableStations} from "../api/station";
import {getCards} from "../api/cards";
import {CARDS, Monthly_CARDS, PRECIPITATION_CARDS, PRECIPITATION_MONTHLY_CARDS} from "../config";
import {useEffect, useState} from "react";
import {CONTEXT_PATH, monthIndexToString, scrollToDocumentId} from "../utils";
import page from "page";


const {setGlobalState, useGlobalState, getGlobalState} = createGlobalState({
  errorMessage: false,
  loading: true,
  stations: false,
  location: '',
  locationId: false,
  parameter: 'temperature',
  currentMonth: new Date().getMonth() + 1,
  currentYear: new Date().getFullYear(),
  currentCardTab: 0,
  currentCard: false,
  cards: {},
  specificEvent: {id: "season_lengths", date: false},
  route: {},
  screenMode: null,
  graphs: {}
});

const MOBILE_RESOLUTION_MAX = 512;
const TABLET_RESOLUTION_MAX = 768;
const SCREEN_MODES = {
  DESKTOP: "desktop",
  TABLET: "tablet",
  MOBILE: "mobile",
};

export function useResponsiveApp() {
  const [, setScreenMode] = useGlobalState("screenMode");

  useEffect(() => {
    let currentMode;

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let mode;
      if (screenWidth >= TABLET_RESOLUTION_MAX) {
        mode = SCREEN_MODES.DESKTOP;
      } else if (
        screenWidth >= MOBILE_RESOLUTION_MAX &&
        screenWidth < TABLET_RESOLUTION_MAX
      ) {
        mode = SCREEN_MODES.TABLET;
      } else if (screenWidth < MOBILE_RESOLUTION_MAX) {
        mode = SCREEN_MODES.MOBILE;
      }

      if (currentMode !== mode) {
        currentMode = mode;
        setScreenMode(mode);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setScreenMode]);
}

export function useScreenMode() {
  const [screenMode] = useGlobalState("screenMode");

  const isDesktop = screenMode === SCREEN_MODES.DESKTOP;
  const isTablet = screenMode === SCREEN_MODES.TABLET;
  const isMobile = screenMode === SCREEN_MODES.MOBILE;

  return { isDesktop, isTablet, isMobile };
}

export function useIsLoading() {
  const [loading] = useGlobalState("loading");
  return [loading];
}

export function useErrorMessage() {
  const [errorMessage] = useGlobalState("errorMessage");
  return [errorMessage];
}

export function useCurrentRoute() {
  const [location] = useGlobalState("location");
  const [parameter] = useGlobalState("parameter");
  return [location, parameter]
}

export function useCurrentMonth() {
  const [currentMonth, setCurrentMonth] = useGlobalState("currentMonth");
  return [currentMonth, setCurrentMonth];
}

export function useCurrentStationIdAndYear() {
  const [station_id] = useGlobalState("locationId");
  const [currentYear] = useGlobalState("currentYear");
  return [station_id, currentYear]
}

export function useCurrentSelectedCard() {
  const [currentCard, setCurrentCard] = useGlobalState("currentCard");

  const updateCurrentCard = (card) => {
    if (card === false) {
      scrollToDocumentId("cards-holder", 0,
        () => document.getElementById("card" + currentCard).focus());
    } else {
      setTimeout(() => { // TODO: scrolla när state är satt istället
        scrollToDocumentId("diagram-och-tabell", -16,
          () => document.getElementsByClassName("graph--scroll-up-btn")[0].focus());
      }, 200);
    }
    setCurrentCard(card);
  };

  return [currentCard, updateCurrentCard];
}

export function useUpdateAppData() {
  const [currentYear, setCurrentYear] = useGlobalState('currentYear');
  const [currentMonth] = useGlobalState('currentMonth');
  const [locationId] = useGlobalState('locationId');
  const [parameter] = useGlobalState('parameter');
  const [cards] = useGlobalState('cards');
  const [, setSpecificEvent] = useGlobalState('specificEvent');

  useEffect(async () => {
    if(parameter === "temperature") {
      setSpecificEvent({id: "season_lengths", date: false})
    } else {
      setSpecificEvent({id: "precipitation_seasons", date: false})
    }
  }, [parameter]);


  useEffect(() => {
    updateCards(locationId, currentMonth, parameter);
  }, [locationId, currentMonth, parameter]);

  useEffect(() => {
    // Check that the year is available in the dataset, otherwise set year to endYear.
    if(cards && cards.meta && parseInt(cards.meta.number) === parseInt(locationId)) {
      const endYear = getAvailableYears(cards).endYear;
      if (endYear >= currentYear) {
      } else {
        setCurrentYear(endYear);
      }
    }
  }, [locationId, currentYear, cards, setCurrentYear, parameter]);
}

export const useCurrentCardTab = () => {
  const [currentCardTab, setCurrentCardTab] = useGlobalState('currentCardTab');
  const [, setCurrentCard] = useGlobalState('currentCard');
  const updateCurrentCardTab = tab => {
    setCurrentCard(false);
    setCurrentCardTab(tab);
  }
  return [currentCardTab, updateCurrentCardTab];
}

export const getCardsToRender = () => {
  const currentCardTab = getGlobalState('currentCardTab');
  const parameter = getGlobalState('parameter');
  if(parameter === 'precipitation') {
    return currentCardTab === 0 ? PRECIPITATION_CARDS : PRECIPITATION_MONTHLY_CARDS;
  }
  const cards = currentCardTab === 0 ? CARDS : Monthly_CARDS;
  return cards;
}

export function useCardsData() {
  const [cards] = useGlobalState('cards');
  return [cards];
}

export const useSelectedCard = () => {
  const [currentCard] = useGlobalState('currentCard');
  const [currentMonth] = useGlobalState('currentMonth');
  const [cardsData] = useGlobalState('cards');
  const [location] = useGlobalState('location');

  const cards = getCardsToRender();
  let prevIndex = currentCard - 1;
  if (prevIndex < 0) {
    prevIndex = cards.length - 1;
  }
  let nextIndex = currentCard + 1;
  if (nextIndex >= cards.length) {
    nextIndex = 0;
  }

  const withRenderedCardLabel = (card, cardIndex = currentCard) => {
    const replaceString = label => {
      const record_range_data = cardsData.meta.data_info.Dygnsmax || cardsData.meta.data_info['Dygnsnederbörd'];
      const average_range_data = cardsData.meta.data_info.Dygnsmedel || cardsData.meta.data_info['Dygnsnederbörd'];
      const average_m_range_data = cardsData.meta.data_info['Månadsmedel'] || cardsData.meta.data_info['Månadsnederbörd']
      const record_range = record_range_data['start-year'] + ' – ' + record_range_data['end-year'];
      const average_m_range = average_m_range_data['start-year'] + ' – ' + average_m_range_data['end-year'];
      const average_range = average_range_data['start-year'] + ' – ' + average_range_data['end-year'];
      return label
        .replace("YEAR", currentCardData.year)
        .replace("CURRENTYEAR", currentCardData.year + 1)
        .replace("MONTH", monthIndexToString(currentMonth))
        .replace("LOCATION", location)
        .replace("RECORD_RANGE", record_range)
        .replace("AVERAGE_M_RANGE", average_m_range)
        .replace("AVERAGE_RANGE", average_range);
    }
    // Get metadata from  /api/cards from backend
    const currentCardData = cardsData.data[cards[cardIndex].data] || {};
    const label = card.carouselLabel || card.graphLabel || card.label;
    const graphLabel = card.graphLabel || card.carouselLabel || card.label;
    return {
      ...card,
      label: replaceString(label),
      graphLabel: replaceString(graphLabel)
    };
  };

  const prevCard = withRenderedCardLabel(cards[prevIndex], prevIndex);
  const selectedCard = withRenderedCardLabel(cards[currentCard], currentCard);
  const nextCard = withRenderedCardLabel(cards[nextIndex], nextIndex);
  return [selectedCard, prevCard, nextCard];
}

export const selectNextCard = () => {
  setGlobalState('currentCard', currentCard => {
    const cards = getCardsToRender();
    let nextIndex = currentCard + 1;
    if (nextIndex >= cards.length) {
      nextIndex = 0;
    }
    return nextIndex;
  })
}
export const selectPrevCard = () => {
  setGlobalState('currentCard', currentCard => {
    const cards = getCardsToRender();
    let prevIndex = currentCard - 1;
    if (prevIndex < 0) {
      prevIndex = cards.length - 1;
    }
    return prevIndex;
  })
}

export const setErrorMessage = (message, err) => {
  console.log("Error Message", message, err);
  console.error(message); // TODO: byt till felmeddelande i HTML
  setGlobalState('errorMessage', message);
};

export const useLocation = () => {
  const [stations] = useGlobalState('stations');
  const [locationId] = useGlobalState('locationId');
  const [parameter] = useGlobalState('parameter');

  const setLocationById = (id) => {
    if (!stations || id === locationId) { return; }
    const l = stations[parameter].find(s => s.number === id);
    if (l) { // TODO: add error/404
      page(`${CONTEXT_PATH}q/${l.name}/${parameter}`);
    } else {
      page(`${CONTEXT_PATH}`);
    }
  }

  return [locationId, setLocationById];
}

export const useParameter = () => {
  const [location] = useGlobalState('location');
  const [parameter] = useGlobalState('parameter');
  const [, setLoading] = useGlobalState('loading');
  const updateParameter = new_parameter => {
    if(new_parameter === parameter) {
      return;
    }
    setLoading(true); // Set state to loading until new cards data is loaded.
    page(`${CONTEXT_PATH}q/${location}/${new_parameter}`);
  }
  return [parameter, updateParameter];
}

function haversine_distance(lat1, lng1, lat2, lng2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2-lat1);  // deg2rad below
  const dLon = deg2rad(lng2-lng1);
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c; // Distance in km
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

const findClosestStation = (stations, lat, lng) => {
  const distances = stations.map(s => {
    const d = haversine_distance(lat, lng, s.lat, s.lng);
    return {
      ...s,
      distance: d
    }
  });
  const sorted = distances.sort((a, b) => a.distance - b.distance);
  return sorted[0];
}

export const useRoute = () => {
  const [route, setRoute] = useGlobalState('route');
  const [stations] = useGlobalState('stations');
  const [, setLocationId] = useGlobalState('locationId');
  const [, setLocationName] = useGlobalState('location');
  const [, setParameter] = useGlobalState('parameter');
  const [, setCurrentCard] = useGlobalState('currentCard');

  useEffect(() => {
    if(route.station && stations) {
      const location = stations[route.parameter].find(s => s.name === route.station);
      if (location) { // TODO: redirect to 404
        setLocationId(location.number);
        setParameter(route.parameter);
        setCurrentCard(false);
        setLocationName(location.name);
        document.title = `Hur var vädret? - ${location.name}`
      } else {
        // Find closest station available
        const oldParameter = route.parameter === 'temperature' ? 'precipitation' : 'temperature';
        const current_station = stations[oldParameter].find(s => s.name === route.station);
        if (!current_station) {
          console.log("failed to find station", route.station, stations);
          page(`${CONTEXT_PATH}`); // Go to root route.
          return;
        }
        const location = findClosestStation(stations[route.parameter], current_station.lat, current_station.lng);
        setLocationId(location.number);
        setParameter(route.parameter);
        setCurrentCard(false);
        setLocationName(location.name);
        document.title = `Hur var vädret? - ${location.name}`
      }
    }
  }, [route, stations]);


  const updateRoute = ({station, parameter}) => {
    if (route.station === station && route.parameter === parameter) {
      return;
    }
    setRoute({station, parameter});
  }

  return [route, updateRoute]
}

export const getAvailableYears = (cards, dataset='Dygnsmedel') => {
  try {
    const range = cards.meta.data_info[dataset];
    return {startYear: range["start-year"], endYear: range["end-year"]};
  } catch (e) {
    return {startYear:0, endYear: 3000};
  }
}

const updateCards = async (locationId, currentMonth, parameter) => {
  if (locationId === false) {
    return;
  }
  setGlobalState('loading', true);
  setGlobalState('cards', false);
  try {
    const cards = await getCards(locationId, currentMonth, parameter);
    setGlobalState('cards', {parameter, ...cards});
  } catch (err) {
    setErrorMessage("Fel vid hämtning av data", err);
    setGlobalState('cards', false);
  }
  setGlobalState('loading', false);
}

export const useSpecificEvent = () => {
  const [specificEvent, setSpecificEvent] = useGlobalState('specificEvent');

  const updateSpecificEvent = async data => {
    if (!data.date) {
      setSpecificEvent({...data, date: false});
    } else {
      setSpecificEvent(data);
    }
  }
  return [specificEvent, updateSpecificEvent];
}

export const useStations = () => {
  const [stations] = useGlobalState('stations');
  const [parameter] = useGlobalState('parameter');
  return [stations ? stations[parameter] : []]
}

export function useGraph(uri) {
  const [graphs, setGraphs] = useGlobalState('graphs');
  const [graph, setGraph] = useState(null);

  useEffect(async () => {
    if (graphs[uri]) {
      setGraph(graphs[uri]);
    } else {
      // console.log("Loading graph", uri);
      const url = `${CONTEXT_PATH}api/${uri}`;
      const response = await fetch(url);
      const json = await response.json();
      setGraphs({...graphs, [uri]: json});
    }
  }, [uri, graph, graphs]);

  return [graph];
}

export async function initializeState() {
  try {
    const temperature = await getAvailableStations();
    const precipitation = await getAvailableStations('precipitation');
    setGlobalState('stations', {
      temperature,
      precipitation
    })
  } catch (err) {
    setErrorMessage("Misslyckades att ladda stationer", err);
  }
}

initializeState();


import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  ReferenceLine,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar
} from "recharts";
import "./Graph.css";
import {useIsLoading, useParameter} from "../../state";
import { Checkbox, Row, Col, Subtitle1, Tabs, Tab, SubText, Link } from "@aurora/widgets-react";
import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { GraphTable } from "./GraphTable";
import { RangeSlider } from "./RangeSlider";
import LoadingIndicator from "../LoadingIndicator";
import ResponsiveWrapper from "../ResponsiveWrapper";
import { GraphInfoCard } from "./GraphInfoCard";

const CHART_HEIGHT = 500;
const UNCERTAINTY_BREAKPOINT = 1933;

export const Graph = ({ ...props }) => {
  return (
    <>
      <Tabs className="graph-tabs">
        <Tab label={"Diagram"} id="diagram-och-tabell-diagram-tab">
          <GraphComponent {...props} />
        </Tab>
        <Tab label={"Tabell"}>
          <GraphTable {...props} />
        </Tab>
      </Tabs>
    </>
  );
};

const formatNumber = (v, unit) => {
  if(v === 0) { return (unit === "°C") ? "0.0" : "0"; }
  if(v > -0.05 && v < 0.05) { return "0.0"; }
  return (Number.isInteger(v) ? (unit === "°C" ? v.toFixed(1) : v) : v.toFixed(1));
}
export const formatGraphValue = (v, unit) => {
  // Ibland kan värdet vara NaN om det saknas i tidsserie, svara då med '-'
  try {
    return Array.isArray(v)
      ? `${formatNumber(v[0], unit)} – ${formatNumber(v[1], unit)}`
      : formatNumber(v, unit);
  } catch (e) {
    return "-";
  }
};

/* Ta fram min-max domän för datat */
function calculateFixedDomain(data, lines, bars, areas, filter) {
  let max = false,
    min = false;

  lines = lines.filter((area) => !filter[area.id]);
  bars = bars.filter((area) => !filter[area.id]);
  areas = areas.filter((area) => !filter[area.id]);

  /*if ([...lines, ...bars, ...areas].length === 1) {
    return ["auto", "auto"];
  }*/

  data.forEach((d) => {
    [...lines, ...bars].forEach((l) => {
      if (!isNaN(d[l.id]) && (max === false || d[l.id] > max)) {
        max = Math.ceil(d[l.id]);
      }
      if (!isNaN(d[l.id]) && (min === false || d[l.id] < min)) {
        min = Math.floor(d[l.id]);
      }
    });
    areas.forEach((l) => {
      if (
        d[l.id] &&
        !isNaN(d[l.id][1]) &&
        (max === false || d[l.id][1] > max)
      ) {
        max = Math.ceil(d[l.id][1]);
      }
      if (
        d[l.id] &&
        !isNaN(d[l.id][0]) &&
        (min === false || d[l.id][0] < min)
      ) {
        min = Math.floor(d[l.id][0]);
      }
    });
  });
  return [min, max];
}

const YAxisLabel = ({label}) => {
  return  <text x="50" y="22" className="recharts-text recharts-label" textAnchor="start">
    {label}
  </text>;
}

/* Used to show a grey rectangle behind the bars for the period that is uncertain. */
class UncertainDataGrid extends CartesianGrid {
  render() {
    const {
      x, y, width, height, data, dataKey = 'year', breakpoint = UNCERTAINTY_BREAKPOINT,
    } = this.props;

    const rangeStart = data[0][dataKey];
    const rangeEnd = data[data.length-1][dataKey];
    const rangeWidth = (breakpoint - rangeStart)/(rangeEnd - rangeStart) * width;

    return <g>
      <CartesianGrid strokeDasharray="3 3" opacity={0.6} {...this.props}/>
      <rect x={x} y={y} width={rangeWidth} height={height} fill={"#EBEEF0"} fillOpacity={0.4}/>
    </g>
  }
}

export const GraphComponent = ({
  data,
  dataKey,
  lines,
  areas,
  bars,
  yAxisLabel,
  tickFormatter,
  ticks,
  tooltipLabelFormatter,
  label,
  children,
  brush,
  renderTooltip,
  fixedDomain,
  updateFixedDomain, // Update domain when changing visible layers in legend
  yAxisPadding,
  event,
  showUncertainty,
  caption,
  sortTooltipValues
}) => {
  const [parameter] = useParameter();
  const [,loadingCharts] = useIsLoading();
  // const [graphWidth, setGraphWidth] = useState(false);
  const graphRef = useRef(null);
  const hasUncertainty = useMemo(() => showUncertainty && !!data && data.some(d => d.year < UNCERTAINTY_BREAKPOINT), [data, showUncertainty]);
  const certainData = useMemo(() => hasUncertainty ? data.filter(d => d.year >= UNCERTAINTY_BREAKPOINT) : data, [data, hasUncertainty]);
  const [graphData, setGraphData] = useState(certainData);
  const [uncertaintyVisible, setUncertaintyVisible] = useState(false);

  // const updateGraphWidth = useCallback(() => {
  //   if (graphRef.current !== null) {
  //     const { width } = graphRef.current.getBoundingClientRect();setGraphWidth
  //     setGraphWidth(width);
  //   }
  // }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     updateGraphWidth();
  //   }

  //   window.addEventListener('resize', handleResize);

  //   updateGraphWidth();

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   }
  // }, [updateGraphWidth]);

  const [domain, setDomain] = useState(["auto", "auto"]);

  // set filter {name: true} for data to remove from graph
  const initialFilterState = useCallback(() => {
    const filter = {};
    [...lines, ...areas, ...bars].forEach(
      (line) => (filter[line.id] = !line.enabled)
    );
    return filter;
  }, [lines, areas, bars]);

  const [filter, setFilter] = useState(initialFilterState());

  useEffect(() => {
    setFilter(initialFilterState());
  }, [lines, areas, bars, initialFilterState]);

  useEffect(() => {
    setGraphData(uncertaintyVisible ? data : certainData);
  }, [data, certainData, uncertaintyVisible]);

  useEffect(() => {
    if (graphData && fixedDomain && updateFixedDomain) {
      // Update graph domain when filter for visible layers changes
      console.log("update domain!");
      setDomain(calculateFixedDomain(graphData, lines, bars, areas, filter));
    }
  }, [graphData, filter])

  const GraphLegend = () => {
    const changeFilter = (event) => {
      const id = event.target.value;
      setFilter({ ...filter, [id]: !filter[id] });
    };
    const renderCheckbox = (line) => (
      <Col s={6} key={`item-${line.id}`}>
        <div className="graph-legend-item">
          <svg width={50} height={50} viewBox={"0 0 50 50"}>
            {line.stroke && (
              <line
                x1={0}
                y1={25}
                x2={50}
                y2={25}
                stroke={line.stroke}
                strokeWidth={5}
                strokeDasharray={line.strokeDasharray}
              />
            )}
            {line.fill && !line.gradient && (
              <line
                x1={10}
                y1={25}
                x2={40}
                y2={25}
                stroke={line.fill}
                strokeWidth={30}
              />
            )}
            {line.gradient && (
              <>
                <defs>
                  <linearGradient
                    id="checkbox-gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor={line.gradient.top} offset="25%" />
                    <stop stopColor={line.gradient.bottom} offset="75%" />
                  </linearGradient>
                </defs>
                <line
                  x1={10}
                  y1={25}
                  x2={40}
                  y2={25}
                  stroke="url(#checkbox-gradient)"
                  strokeWidth={30}
                />
              </>
            )}
          </svg>
          <Checkbox
            label={line.name}
            value={line.id}
            checked={!filter[line.id]}
            onChange={changeFilter}
          />
        </div>
      </Col>
    );

    return (
      <div id="graph-legend">
        <Row>
          <Col xs={12}>
            <Row className="graph-legend-row">
              {bars.map(renderCheckbox)}
              {lines.map(renderCheckbox)}
              {areas.map(renderCheckbox)}
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const renderToolTipInternal = ({ payload, label }) => {
    if (!payload) {
      return null;
    }
    if(sortTooltipValues) {
      payload.sort((a,b) => b.value - a.value)
    }
    return (
      <div className="graph-custom-tooltip">
        <p className="graph-custom-tooltip-label">
          {tooltipLabelFormatter(label, payload)}
        </p>
        {payload.map(({ name, value, strokeDasharray, stroke, fill, unit }) => (
          <p key={name} className="graph-custom-tooltip-param">
            <svg width={20} height={20} viewBox={"0 0 50 50"}>
              {Array.isArray(value) || stroke === undefined ? (
                <line
                  x1={10}
                  y1={25}
                  x2={40}
                  y2={25}
                  stroke={fill}
                  strokeWidth={30}
                />
              ) : (
                <line
                  x1={0}
                  y1={25}
                  x2={50}
                  y2={25}
                  stroke={stroke}
                  strokeWidth={5}
                  strokeDasharray={strokeDasharray}
                />
              )}
            </svg>
            <span>
              {name}: {formatGraphValue(value, unit || yAxisLabel || "°C")} {unit || yAxisLabel || "°C"}
            </span>
          </p>
        ))}
        {hasUncertainty && <SubText
          className="uncertantity-text">{parseInt(label) < UNCERTAINTY_BREAKPOINT && "Observationer innan år 1933 anses ha lägre tillförlitlighet än senare observationer."}</SubText>}
      </div>
    );
  };

  const getTicks = () => {
    if(ticks && graphData) {
      // Filtrera ut ticks som inte finns med i graphData, för att undvika bugg som gör att alla ticks inte visas när användaren begränsar tidsserien med RangeSlider
      return ticks.filter(tick => graphData.find(d => d[dataKey] === tick));
    }

    return ticks;
  }

  const toggleUncertainData = () => {
    setUncertaintyVisible(visible => !visible);
  }

  const eventDateNotSet = event !== undefined && event.id.endsWith('specific_date') && !event.date;
  return (
    <div className="graph" ref={graphRef} aria-label={"Diagram " + label}>
      {!loadingCharts && !eventDateNotSet && graphData && (
        <>
          {/* Use wrapper-component to fix bug that causes container to not respond
            when window is resized (see: https://github.com/recharts/recharts/issues/172) */}
          <ResponsiveWrapper height={CHART_HEIGHT}>
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart
                data={graphData}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                barGap={2}
              >
                {hasUncertainty ?
                  <UncertainDataGrid data={graphData} strokeDasharray="3 3" opacity={0.6} /> :
                  <CartesianGrid strokeDasharray="3 3" opacity={0.6}/>}
                <defs>
                  <linearGradient id="tempGrad" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="25%" stopColor="#F82B37" stopOpacity="1" />
                    <stop offset="75%" stopColor="#3B9CDF" stopOpacity="1" />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey={dataKey}
                  minTickGap={20}
                  tickFormatter={tickFormatter}
                  tickLine={false}
                  ticks={getTicks()}
                />
                <ReferenceLine y={0} stroke="#000000" />
                <YAxis
                  width={35}
                  allowDecimals={false}
                  domain={domain}
                  padding={yAxisPadding}
                  label={<YAxisLabel label={yAxisLabel}/>}
                />

                <Tooltip
                  content={renderTooltip ? renderTooltip : renderToolTipInternal}
                />
                {areas
                  .filter((area) => !filter[area.id])
                  .map((area) => (
                    // <Area key={area.id} type="monotone" unit={area.unit} name={area.name || area.id} dataKey={area.id} fill={area.fill}/>
                    <Area
                      key={area.id}
                      type="monotone"
                      unit={area.unit}
                      name={area.name || area.id}
                      dataKey={area.id}
                      fill="url(#tempGrad)"
                      strokeOpacity={0.1}
                    />
                  ))}
                {bars
                  .filter((bar) => !filter[bar.id])
                  .map((bar) => (
                    <Bar
                      key={bar.id}
                      unit={bar.unit}
                      name={bar.name || bar.id}
                      barSize={20}
                      dataKey={bar.id}
                      fill={bar.fill}
                    />
                  ))}
                {lines
                  .filter((line) => !filter[line.id])
                  .map((line) => (
                    <Line
                      key={line.id}
                      type="monotone"
                      strokeDasharray={line.strokeDasharray || undefined}
                      unit={line.unit}
                      name={line.name || line.id}
                      dataKey={line.id}
                      stroke={line.stroke}
                      strokeWidth={1.5}
                      dot={false}
                    />
                  ))}
                {/* <Brush
              travellerWidth={16}
              tickFormatter={tickFormatter}
              x={100}
              width={graphWidth && graphWidth - 150}
              alwaysShowText={true}
              dataKey={dataKey}
              stroke="#647078"
            /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </ResponsiveWrapper>
          {/*{caption && <div className="graph-caption">{caption}</div>}*/}
          {brush && <RangeSlider data={uncertaintyVisible ? data : certainData} dataKey={dataKey} setData={setGraphData} tickFormatter={tickFormatter} />
          }
        </>
      )}
      {!loadingCharts && eventDateNotSet && <div className="graph-specific-date-notice"><Subtitle1>Välj ett datum ovan</Subtitle1></div>}
      {(loadingCharts || (!eventDateNotSet && !data)) && (
        <LoadingIndicator className="graph-loading" />
      )}
      <GraphLegend />
      {hasUncertainty && (
        <div className="uncertainty-toggle">
          <Checkbox
            label={'Inkludera årtal med lägre tillförlitlighet'}
            checked={uncertaintyVisible}
            onChange={toggleUncertainData}
          />
        </div>
      )}
      {eventDateNotSet ? (
        <GraphInfoCard
          label="Specifikt datum"
          infoText={
            (parameter === 'temperature' && (
              <>
                <p>Välj ett specifikt datum för att kika på hur temperaturen varit just den dagen sedan mätstart på den mätstation/plats som du valt.</p>
                <Link to="https://smhi.se/kunskapsbanken">
                  Läs mer i SMHIs kunskapsbank
                </Link>
              </>
            )) ||
            (parameter === 'precipitation' && (
              <>
                <p>Välj ett specifikt datum för att kika på hur ofta nederbörd har förekommit just den dagen sedan mätstart på den mätstation/plats som du valt.</p>
                <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
                  Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
                </Link>
              </>
            ))
          }
        />
      ) : (
        children
      )}
    </div>
  );
};

GraphComponent.defaultProps = {
  dataKey: "date",
  lines: [],
  areas: [],
  bars: [],
  yAxisLabel: "°C",
  tickFormatter: undefined,
  tooltipLabelFormatter: (id)=> id,
  children: null,
  brush: true,
  renderTooltip: false,
  fixedDomain: true,
  updateFixedDomain: false,
  showUncertainty: false,
  caption: false,
  sortTooltipValues: false
}

import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
const MONTH_MAP_LONG = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September',
  'Oktober', 'November', 'December'];
const formatMonth = m => MONTH_MAP[m-1];
const formatMonthLong = m => MONTH_MAP_LONG[m-1];

export const TemperatureMonthlyAverageGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `temperature/monthly_average/${stationId}`;

  const GRAPH_BARS = [
    {id: `monthly_average_${currentYear - 1}`, fill: "#3B9CDF", name: `Månadsmedel ${currentYear - 1}`, enabled: true},
    {id: `monthly_average_${currentYear}`, fill: "#99D6FF", name: `Månadsmedel ${currentYear}`},
    // {id: `monthly_average_1961-1990`, fill: "#3B9CDF", name: `Normalvärde 1961-1990`},
    // {id: `monthly_average_1991-2020`, fill: "#F82B37", name: `Normalvärde 1991-2020`}
  ];
  return (
    <GraphURI
      uri={uri}
      dataKey="month"
      bars={GRAPH_BARS}
      tickFormatter={formatMonth}
      tooltipLabelFormatter={formatMonthLong}
      {...props}
    />
  );
}

import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

const MONTH_MAP_LONG = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const PrecipitationMonthlySeriesGraph = (props) => {
  const [stationId] = useCurrentStationIdAndYear()
  const [currentMonth] = useCurrentMonth();
  const uri = `precipitation/month_series/${stationId}/${currentMonth}`;
  const bars = [
    { id: "Månadsnederbörd", fill: "#74BCED", name: `Månadsnederbörd ${MONTH_MAP_LONG[currentMonth - 1]}`,
      enabled: true, unit: 'mm' },
  ];
  const lines = [
    {id: `rolling`, stroke: "#0C6BC4", name: `Trend - månadsnederbörd`, enabled: false, unit: "mm"},
  ];
  return <GraphURI uri={uri} dataKey="year" bars={bars} lines={lines}
                   yAxisLabel='mm'
                   showUncertainty={true}
                   {...props} />;
};

import { Link } from "@aurora/widgets-react";
import React from "react";

export const Monthly_CARDS = [
  { // Bubbla #1
    label: "Temperaturrekord", data: "monthly_absolute_records", card: "MinMaxCard", graph: "TemperatureMonthlyRecordGraph",
    graphLabel: "Temperaturrekord\nMONTH RECORD_RANGE",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-rekord-for-ars-och-manadsmedeltemperatur-1.34561">
        Verkligen Läs mer om temperaturrekord i SMHIs kunskapsbank
      </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar ett spann med den högsta och lägsta uppmätta temperaturen per dygn för vald månad och mätstation, sedan mätstart.</p>
      <p>Den streckade linjen i diagrammet visar årets högsta och lägsta uppmätta temperatur för månaden.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-rekord-for-ars-och-manadsmedeltemperatur-1.34561">
        Verkligen Läs mer om temperaturrekord i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #2
    label: "Medeltemperatur",
    subLabel: "MONTH_CAPITALIZED YEAR",
    graphLabel: "Medeltemperatur\nMONTH YEAR",
    data: "monthly_average_and_change", card: "AverageCard", graph: "TemperatureDailyAverageGraph",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/hur-beraknas-medeltemperatur-1.3923">
      Läs mer i SMHIs kunskapsbank om medeltemperatur.
    </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat per dygn för vald månad och mätstation/plats.</p>
      <p>Dygnsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar under ett dygn.</p>
      <p>I diagrammet kan du jämföra föregående års medeltemperatur med nuvarande år.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/hur-beraknas-medeltemperatur-1.3923">
      Läs mer i SMHIs kunskapsbank om medeltemperatur.
    </Link>
    </>
  },
  { // Bubbla #3
    label: "Månadsmedeltemperatur",
    graphLabel: "Månadsmedeltemperatur\nMONTH AVERAGE_M_RANGE",
    data: "monthly_average_records", card: "MonthlyMinMaxCard", graph: "TemperatureMonthlyAverageSeriesGraph",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-rekord-for-ars-och-manadsmedeltemperatur-1.34561">
      Läs mer i SMHIs kunskapsbank om svenska rekord för månadsmedeltemperaturer.
    </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat för vald månad, sedan mätstart.</p>
      <p>Månadsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar per dygn under en månad.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-rekord-for-ars-och-manadsmedeltemperatur-1.34561">
      Läs mer i SMHIs kunskapsbank om medeltemperatur.
    </Link>
    </>
  },
  { // Bubbla #4
    label: "Normalvärde 1961 – 1990", subLabel: "Medeltemperatur MONTH", data: "monthly_average_and_change", card: "NormTempCard1961", graph: "TemperatureMonthlyNormGraph1961_1990",
    graphLabel: "Klimatologiskt normalvärde\nMONTH 1961 – 1990",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>,
    infoText: <>
      <p>Den blå linjen i diagrammet visar medeltemperaturen för vald månad under en mätperiod på 30 år. Denna linje kallas för klimatologiskt normalvärde för mätperioden 1961 – 1990.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året under vald månad.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>
  },
  { // Bubbla #5
    label: "Normalvärde 1991 – 2020", subLabel: "Medeltemperatur MONTH", data: "monthly_average_and_change", card: "NormTempCard1991", graph: "TemperatureMonthlyNormGraph1991_2020",
    graphLabel: "Klimatologiskt normalvärde\nMONTH 1991 – 2020",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>,
    infoText: <>
      <p>Den röda linjen i diagrammet visar medeltemperaturen för vald månad under en mätperiod på 30 år. Denna linje kallas för klimatologiskt normalvärde för mätperioden 1991 – 2020.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året under vald månad.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>
  },
  { // Bubbla #6
    label: "Temperaturförändring", subLabel: "Mellan mätperioder\n1961 – 1990 och 1991 – 2020", data: "monthly_average_and_change", card: "ChangesCard", graph: "TemperatureMonthlyNormGraph",
    carouselLabel: "Temperaturförändring\nMONTH",
    graphLabel: "Temperaturförändring mellan mätperioder i MONTH",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>,
    infoText: <>
      <p>Den blå och röda linjen i diagrammet visar medeltemperaturen för vald månad under en mätperiod på 30 år. Dessa linjer kallas för klimatologiskt normalvärde för mätperioden 1961 – 1990 respektive 1991 – 2020.</p>
      <p>Glappet mellan den blå och röda linjen visar hur klimatet på platsen har förändrats mellan mätperioderna.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året under vald månad.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.0</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/sveriges-klimat/temperaturens-okning-i-sverige-sedan-1800-talet-1.158913">
      Läs mer i SMHIs kunskapsbank om temperaturens förändring i Sverige
    </Link>
    </>
  }
]


export const CARDS = [
  { // Bubbla #1
    label: "Temperaturrekord", data: "yearly_absolute_records", card: "MinMaxCard", graph: "TemperatureRecordGraph",
    carouselLabel: "Temperaturrekord\nRECORD_RANGE",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-rekord-for-ars-och-manadsmedeltemperatur-1.34561">
        Läs mer om svenska temperaturrekord i SMHIs kunskapsbank
      </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar ett spann med den högsta och lägsta uppmätta temperaturen på vald plats, sedan mätstart.</p>
      <p>Den streckade linjen i diagrammet visar årets högsta och lägsta uppmätta temperatur hittills i år.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-temperaturrekord-1.5792">
        Läs mer om temperaturrekord i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/meteorologiska-matningar/vad-ar-stationsfoljning-1.185838">
        Läs om stationsföljning i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #2
    label: "Medeltemperatur",
    subLabel:  "År YEAR",
    carouselLabel: "Medeltemperatur\nYEAR",
    graphLabel: "Medeltemperatur",
    data: "yearly_average_and_change", card: "AverageCard", graph: "TemperatureMonthlyAverageGraph",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/hur-beraknas-medeltemperatur-1.3923">
      Läs mer i SMHIs kunskapsbank om medeltemperatur.
    </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat per månad under föregående år, på vald mätstation/plats.</p>
      <p>Månadsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar per dygn under en månad.</p>
      <p>I diagrammet kan du jämföra föregående års medeltemperaturer med nuvarande år.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/hur-beraknas-medeltemperatur-1.3923">
    Läs mer i SMHIs kunskapsbank om medeltemperatur.
    </Link>
    </>
  },
  { // Bubbla #3
    label: "Årsmedeltemperatur", graphLabel: "Årsmedeltemperatur AVERAGE_M_RANGE", data: "yearly_average_records", card: "MinMaxCard", graph: "TemperatureYearlyAverageGraph",
    carouselLabel: "Årsmedeltemperatur\n AVERAGE_M_RANGE",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/sveriges-klimat/sverigemedeltemperatur-1.21151">
      Läs mer i SMHIs kunskapsbank om medeltemperaturen för Sverige
    </Link>
    </>,
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat per år för vald plats, sedan mätstart.</p>
      <p>Årsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar per månad under ett år.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/svenska-temperaturrekord-1.5792">
        Läs mer om årsmedeltemperatur i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/meteorologiska-matningar/vad-ar-stationsfoljning-1.185838">
        Läs om stationsföljning i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #4
    label: "Normalvärde 1961 – 1990", subLabel: "Årsmedeltemperatur", data: "yearly_average_and_change", card: "NormTempCard1961", graph: "TemperatureNormGraph1961_1990",
    carouselLabel: "Klimatologiskt normalvärde\n1961 – 1990",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>,
    infoText: <>
      <p>Den blå linjen i diagrammet visar medeltemperaturen under en mätperiod på 30 år, för vald plats. Denna linje kallas för klimatologiskt normalvärde för mätperioden 1961 - 1990.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>
  },
  { // Bubbla #5
    label: "Normalvärde 1991 – 2020", subLabel: "Årsmedeltemperatur", data: "yearly_average_and_change", card: "NormTempCard1991", graph: "TemperatureNormGraph1991_2020",
    carouselLabel: "Klimatologiskt normalvärde\n1991 – 2020",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>,
    infoText: <>
      <p>Den röda linjen i diagrammet visar medeltemperaturen under en mätperiod på 30 år, för vald plats. Denna linje kallas för klimatologiskt normalvärde för mätperioden 1991 – 2020.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>
  },
  { // Bubbla #6
    label: "Temperaturförändring", subLabel: "Mellan mätperioder\n1961 – 1990 och 1991 – 2020", data: "yearly_average_and_change", card: "YearlyChangesCard", graph: "TemperatureNormGraph",
    graphLabel: "Temperaturförändring mellan mätperioder",
    carouselLabel: "Temperaturförändring",
    cardInfoText: <>
      <p>Text till baksidan av kortet här.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/sveriges-klimat/temperaturens-okning-i-sverige-sedan-1800-talet-1.158913">
      Läs mer i SMHIs kunskapsbank om temperaturens förändring i Sverige
    </Link>
    </>,
    infoText: <>
      <p>Den blå och röda linjen i diagrammet visar medeltemperaturen under en mätperiod på 30 år, för vald plats. Dessa linjer kallas för klimatologiskt normalvärde för mätperioden 1961 – 1990 respektive 1991 – 2020.</p>
      <p>Glappet mellan den blå och röda linjen visar hur klimatet på platsen har förändrats mellan mätperioderna.</p>
      <p>I diagrammet kan du jämföra hur vädret kan skifta temperaturmässigt under en kortare tidsperiod. Aktivera dygnsmedel för att se hur temperaturen skiftade förra året.</p>
      <p>Vad är det för skillnad på väder och klimat? Det korta svaret är att de hänger ihop, men att de baseras på olika tidsskalor. Klimatet visar en uppmätt medeltemperatur under minst 30 år, medan vädret har en kortare tidsskala.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
      Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
    </Link>
    </>
    },
];

export const PRECIPITATION_CARDS = [
  {// Bubbla #1
    label: "Nederbördsrekord dygn",
    subLabel: "",
    carouselLabel: "Nederbördsrekord dygn\nRECORD_RANGE",
    data: "daily_precipitation_record", card: "PrecipitationRecordCard", graph: "PrecipitationRecordGraph",
    infoText: <>
      <p>Diagrammet visar den högsta uppmätta dygnsnederbörden under årets samtliga dagar sedan mätstart på vald plats.</p>
      <p>I diagrammet kan du välja att jämföra nederbördsrekord med den senaste uppmätta dygnsnederbörden.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/svenska-nederbordsrekord-1.6660">
        Läs mer om svenska nederbördsrekord i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/meteorologiska-matningar/vad-ar-stationsfoljning-1.185838">
        Läs om stationsföljning i SMHIs kunskapsbank
      </Link>
    </>
  },
  {// Bubbla #2
    label: "Årsnederbörd",
    subLabel:  "År YEAR",
    carouselLabel: "Årsnederbörd\nYEAR",
    graphLabel: "Årsnederbörd",
    data: "yearly_average_and_change", card: "AverageCard", graph: "PrecipitationMonthlyAverageGraph",
    infoText: <>
      <p>Diagrammet visar hur årsnederbörden har varierat per månad under föregående år på vald mätstation/plats.</p>
      <p>I diagrammet kan du välja att jämföra föregående års månadsnederbörd med nuvarande år.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  {// Bubbla #3
    label: "Årsnederbördsrekord", data: "yearly_precipitation_records", card: "MinMaxCard", graph: "PrecipitationYearlyGraph",
    carouselLabel: "Årsnederbördsrekord\nAVERAGE_M_RANGE",
    infoText: <>
      <p>Diagrammet visar den högsta uppmätta årsnederbörden på vald plats, sedan mätstart.</p>
      <p>Trendlinjen i diagrammet visar hur det årliga nederbördsrekordet har varierat på den valda platsen sedan mätstart fram till idag.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/svenska-nederbordsrekord-1.6660">
        Läs mer om svenska nederbördsrekord i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/meteorologiska-matningar/vad-ar-stationsfoljning-1.185838">
        Läs om stationsföljning i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #4
    label: "Normalvärde 1961 – 1990", subLabel: "Medelnederbörd", data: "yearly_average_and_change", card: "NormTempCard1961", graph: "PrecipitationNormGraph1961_1990",
    carouselLabel: "Klimatologiskt normalvärde\n1961 – 1990",
    infoText: <>
      <p>Diagrammet visar medelvärdet av samtliga nederbördsobservationer under en mätperiod på 30 år, för vald plats. Detta medelvärde kallas för klimatologiskt normalvärde för mätperioden 1961 - 1990.</p>
      <p>I diagrammet kan du välja att jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #5
    label: "Normalvärde 1991 – 2020", subLabel: "Medelnederbörd", data: "yearly_average_and_change", card: "NormTempCard1991", graph: "PrecipitationNormGraph1991_2020",
    carouselLabel: "Klimatologiskt normalvärde\n1991 – 2020",
    infoText: <>
      <p>Diagrammet visar medelvärdet av samtliga nederbördsobservationer under en mätperiod på 30 år, för vald plats. Detta medelvärde kallas för klimatologiskt normalvärde för mätperioden 1991 - 2020.</p>
      <p>I diagrammet kan du välja att jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #6
    label: "Nederbördsförändring", subLabel: "Mellan mätperioder\n1961 – 1990 och 1991 – 2020", data: "yearly_average_and_change", card: "YearlyChangesCard", graph: "PrecipitationNormGraph",
    graphLabel: "Nederbördsförändring mellan mätperioder",
    carouselLabel: "Nederbördsförändring",
    infoText: <>
      <p>Staplarna i diagrammet visar medelvärdet av nederbördsobservationer för årets samtliga månader under två mätperioder på 30 år, på vald plats. Dessa medelvärden kallas för klimatologiskt normalvärde för mätperioden 1961 – 1990 respektive 1991 – 2020.</p>
      <p>Glappet mellan staplarna visar hur klimatet på platsen har förändrats mellan mätperioderna.</p>
      <p>I diagrammet kan du välja att jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  }
];

export const PRECIPITATION_MONTHLY_CARDS = [
  { // Bubbla #1
    label: "Nederbördsrekord dygn", data: "monthly_absolute_records", card: "PrecipitationRecordCard", graph: "PrecipitationMonthlyRecordGraph",
    graphLabel: "Nederbördsrekord dygn\nMONTH RECORD_RANGE",
    infoText: <>
      <p>Diagrammet visar den högsta uppmätta dygnsnederbörden under vald månad och plats sedan mätstart.</p>
      <p>I diagrammet kan du välja att jämföra nederbördsrekord med den senaste uppmätta dygnsnederbörden.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/svenska-nederbordsrekord-1.6660">
        Läs mer om svenska nederbördsrekord i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #2
    label: "Månadsnederbörd",
    subLabel: "MONTH_CAPITALIZED YEAR",
    graphLabel: "Månadsnederbörd\nMONTH YEAR",
    data: "monthly_average_and_change", card: "AverageCard", graph: "PrecipitationDailyAverageGraph",
    infoText: <>
      <p>Diagrammet visar hur nederbörden har varierat per dygn på vald månad och mätstation/plats under föregående år.</p>
      <p>I diagrammet kan du välja att jämföra hur nederbörden har varierat mellan föregående och nuvarande år.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #3
    label: "Månadsnederbördsrekord",
    graphLabel: "Månadsnederbördrekord\nMONTH AVERAGE_M_RANGE",
    data: "monthly_average_records", card: "MonthlyMinMaxCard", graph: "PrecipitationMonthlySeriesGraph",
    infoText: <>
      <p>Diagrammet visar nederbördsrekordet för vald månad under alla år sedan mätstart, på vald mätstation/plats.</p>
      <p>Trendlinjen i diagrammet visar hur nederbördsrekordet för vald månad årligen har varierat från mätstart fram till idag.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/svenska-nederbordsrekord-1.6660">
        Läs mer om svenska nederbördsrekord i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #4
    label: "Normalvärde 1961 – 1990", subLabel: "Medelnederbörd MONTH", data: "monthly_average_and_change", card: "NormTempCard1961", graph: "PrecipitationMonthlyNormGraph1961_1990",
    graphLabel: "Klimatologiskt normalvärde\nMONTH 1961 – 1990",
    infoText: <>
      <p>Diagrammet visar medelvärdet av samtliga nederbördsobservationer under en mätperiod på 30 år, för vald månad och plats. Detta medelvärde kallas för klimatologiskt normalvärde för mätperioden 1961 - 1990.</p>
      <p>I diagrammet kan du jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #5
    label: "Normalvärde 1991 – 2020", subLabel: "Medelnederbörd MONTH", data: "monthly_average_and_change", card: "NormTempCard1991", graph: "PrecipitationMonthlyNormGraph1991_2020",
    graphLabel: "Klimatologiskt normalvärde\nMONTH 1991 – 2020",
    infoText: <>
      <p>Diagrammet visar medelvärdet av samtliga nederbördsobservationer under en mätperiod på 30 år, för vald månad och plats. Detta medelvärde kallas för klimatologiskt normalvärde för mätperioden 1991 - 2020.</p>
      <p>I diagrammet kan du jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  },
  { // Bubbla #6
    label: "Nederbördsförändring", subLabel: "Mellan mätperioder\n1961 – 1990 och 1991 – 2020", data: "monthly_average_and_change", card: "ChangesCard", graph: "PrecipitationMonthlyNormGraph",
    carouselLabel: "Nederbördsförändring\nMONTH",
    graphLabel: "Nederbördsförändring mellan mätperioder i MONTH",
    infoText: <>
      <p>Staplarna i diagrammet visar medelvärdet av nederbördsobservationer för en viss månad under två mätperioder på 30 år, på vald plats. Dessa medelvärden kallas för klimatologiskt normalvärde för mätperioden 1961 – 1990 respektive 1991 – 2020.</p>
      <p>Glappet mellan staplarna visar hur klimatet på platsen har förändrats mellan mätperioderna.</p>
      <p>I diagrammet kan du jämföra hur nederbörden kan skifta under en kortare tidsperiod.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/klimat/normaler/vad-ar-normalperioder-1.4087">
        Läs mer om normalvärden och normalperioder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link>
    </>
  }
]



export const WEATHER_HISTORY_INFO = {
  'season_lengths': {label: 'Årstider',
    infoText: <>
      <p>Trendlinjerna i diagrammet visar hur årstidernas längd har förändrats sedan mätstart, på den valda platsen.
      Antal dygn per årstid, kan variera jämfört med trendlinjen dvs. årstidens medelvärde.</p>
      <strong>Villkor för årstidsstart</strong>
      <ul className="no-bullets">
        <li><span className="cursive">Sommar:</span> Medeltemperaturen är högre eller lika med 10,0°C under fem dygn i följd.</li>
        <li><span className="cursive">Vinter:</span> Medeltemperaturen är lägre eller lika med 0,0°C under fem dygn i följd.</li>
        <li><span className="cursive">Höst:</span> Medeltemperaturen är lägre än 10,0°C under fem dygn i följd.</li>
        <li><span className="cursive">Vår:</span> Medeltemperaturen är högre än 0,0°C och lägre än 10,0°C under sju dygn i följd.</li>
      </ul>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/arstider-1.1082">
      Läs mer om årstider i SMHIs kunskapsbank
    </Link></>
    },
  'christmas_eve': {label: 'Julafton',
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat på julafton på vald mätstation/plats, sedan mätstart.</p>
      <p>Dygnsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar under ett dygn.</p>
      <p>I diagrammet kan du även jämföra dygnsmedel med den högsta och lägsta uppmätta temperaturen för tidpunkten.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/julvader-1.4274">
      Läs mer om julväder i SMHIs kunskapsbank
    </Link></>
    },
  'new_years_eve': {label: 'Nyårsafton',
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat på nyårsafton på vald mätstation/plats, sedan mätstart.</p>
      <p>Dygnsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar under ett dygn.</p>
      <p>I diagrammet kan du även jämföra dygnsmedel med den högsta och lägsta uppmätta temperaturen för tidpunkten.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nyarsvader-1.6317">
      Läs mer om nyårsväder i SMHIs kunskapsbank
    </Link></>
    },
  'midsummer_eve': {label: 'Midsommar',
    infoText: <>
      <p>Diagrammet visar hur medeltemperaturen har varierat på midsommarafton på vald mätstation/plats, sedan mätstart.</p>
      <p>Dygnsmedeltemperaturen är medelvärdet av ett visst antal temperaturavläsningar under ett dygn.</p>
      <p>I diagrammet kan du även jämföra dygnsmedel med den högsta och lägsta uppmätta temperaturen för tidpunkten.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/midsommarvader-1.4230">
      Läs mer om midsommarväder i SMHIs kunskapsbank
    </Link></>
    },
  'tropical_nights': {label: 'Tropiska nätter',
    infoText: <>
      <p>När temperaturen inte understiger 20°C på hela dygnet, så kallas det för tropiskt dygn eller tropisk natt.</p>
      <p>Diagrammet visar antal tropiska dygn per år på vald plats, sedan mätstart.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/tropiska-natter-1.1085">
      Läs mer om tropiska nätter i SMHIs kunskapsbank
    </Link></>
    },
  'warm_summer_days': {label: 'Högsommardagar',
    infoText: <>
      <p>När temperaturen är minst 25,0°C någon gång under ett dygn så kallas det för högsommardag.</p>
      <p>Diagrammet visar antal högsommardagar per år för vald plats, sedan mätstart.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/varmebolja-1.22372">
      Läs mer om högsommardagar i SMHIs kunskapsbank
    </Link></>
    },
  'heatwaves': {label: 'Värmeböljor',
    infoText:  <>
      <p>När den högsta uppmätta dygnstemperaturen är minst 25,0°C eller högre under fem sammanhängande dagar så kallas det för värmebölja.</p>
      <p>Diagrammet visar antal värmeböljor per år för vald plats, sedan mätstart.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/klimat/varmebolja-1.22372">
      Läs mer om värmeböljor i SMHIs kunskapsbank
    </Link></>
    },
  'specific_date': {label: 'Hur var vädret',
   infoText: <><p>Du har valt att visa temperaturutvecklingen för ett specifikt datum.
      Diagrammet visar dygnsmedel, dygnsmax och dygnsmin för vald plats sedan mätstart.</p>
     <Link to="https://smhi.se/kunskapsbanken">Läs mer i SMHIs kunskapsbank</Link></>
  },

  'precipitation_midsummer_eve': {label: 'Midsommar',
    infoText: <>
    <p>Diagrammet visar hur nederbörden har varierat på midsommarafton på vald mätstation/plats sedan mätstart.</p>
    <p>Nederbörd mäts i millimeter (mm), 1 mm motsvarar 1 liter regn per kvadratmeter.</p>
    <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/midsommarvader-1.4230">
      Läs mer om midsommarväder i SMHIs kunskapsbank
    </Link></>
  },

  'precipitation_christmas': {label: 'Julafton',
    infoText: <>
      <p>Diagrammet visar hur nederbörden har varierat på julafton på vald mätstation/plats sedan mätstart.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/arstider/vinter/julvader-1.4274">
        Läs mer om julväder i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/sno-och-hagel/hur-mats-snodjup-1.27291">
        Läs mer om hur snödjup mäts i SMHIs kunskapsbank
      </Link></>
  },

  'precipitation_new_year': {label: 'Nyårsafton',
    infoText: <>
    <p>Diagrammet visar hur nederbörden har varierat på nyårsafton på vald mätstation/plats sedan mätstart.</p>
    <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
    <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/arstider/vinter/nyarsvader-1.6317">
      Läs mer om nyårsväder i SMHIs kunskapsbank
    </Link>
    <br/>
    <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/sno-och-hagel/hur-mats-snodjup-1.27291">
      Läs mer om hur snödjup mäts i SMHIs kunskapsbank
    </Link></>
  },

  'precipitation_daysofdrought': {label: 'Antal dagar utan nederbörd',
    infoText: <>
      <p>Diagrammet visar antal dagar per år utan nederbörd på vald mätstation/plats sedan mätstart.</p>
      <p>I diagrammet kan du även välja att se det högsta antalet dagar i rad utan nederbörd genom åren.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/hydrologi/torka/torka-1.111075">
        Läs mer om torka i SMHIs kunskapsbank
      </Link>
    </>
  },
  'precipitation_daysgt0': {label: 'Antal dagar med nederbörd',
    infoText: <>
      <p>Diagrammet visar antal dagar per år med nederbörd på vald mätstation/plats sedan mätstart.</p>
      <p>I diagrammet kan du även välja att se det högsta antalet dagar i rad med nederbörd genom åren.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/nederbord-1.361">
        Läs mer om nederbörd i SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/">
        Läs mer om regn i SMHIs kunskapsbank
      </Link>
    </>
  },
  'precipitation_daysgt10': {label: 'Antal dagar med nederbörd över 10 mm',
    infoText: <>
      <p>Diagrammet visar antal dagar per år med nederbörd över 10mm per dygn på vald mätstation/plats sedan mätstart.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
      <p>I diagrammet kan du även välja att se det högsta antalet dagar i rad med över 10 mm nederbörd genom åren.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/nederbordsintensitet-1.19163">
        Läs mer om nederbördsintensitet SMHIs kunskapsbank
      </Link>
    </>
  },
  'precipitation_daysgt20': {label: 'Antal dagar med nederbörd över 20 mm',
    infoText: <>
      <p>Diagrammet visar antal dagar per år med nederbörd över 20 mm per dygn på vald mätstation/plats sedan mätstart.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form. Mer än 15 mm per dygn brukar definieras som riklig nederbörd.</p>
      <p>I diagrammet kan du även välja att se det högsta antalet dagar i rad med över 20 mm nederbörd genom åren.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/nederbord/nederbordsintensitet-1.19163">
        Läs mer om nederbördsintensitet SMHIs kunskapsbank
      </Link>
      <br/>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/rotblota-1.17339">
        Läs mer om skyfall och rotblöta SMHIs kunskapsbank
      </Link>
    </>
  },
  'precipitation_specific_date': {label: 'Hur var vädret',
    infoText: <>
      <p>Du har valt att visa nederbörden för en viss plats på ett visst datum.</p>
      <p>Nederbörd mäts i millimeter (mm) 1 mm motsvarar 1 liter per kvadratmeter. 1 cm nysnö motsvarar ungefär 1 mm nederbörd i smält form.</p>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link></>
  },
  'precipitation_seasons': {label: 'Årstider',
    infoText: <>
      <p>Trendlinjerna i diagrammet visar hur nederbördsmängden per årstid har varierat sedan mätstart på den valda platsen.</p>
      <strong>Villkor för årstiderna</strong>
      <ul className="no-bullets">
        <li><span className="cursive">Sommar:</span> Juni, Juli och Augusti</li>
        <li><span className="cursive">Vinter:</span> December föregående år, Januari och Februari</li>
        <li><span className="cursive">Höst:</span> September, Oktober och November</li>
        <li><span className="cursive">Vår:</span> Mars, April och Maj</li>
      </ul>
      <p>Notera att observationer innan år 1933 har lägre tillförlitlighet än senare observationer.</p>
      <Link to="https://www.smhi.se/kunskapsbanken/meteorologi/regn/hur-mats-nederbord-1.637">
        Läs mer om hur nederbörd mäts i SMHIs kunskapsbank
      </Link></>
  },
}

import {Button, Row, Col, H3, Body1, Body2} from "@aurora/widgets-react";
import React from "react";
import {ChevronRightOutline, ChevronLeftOutline} from "@aurora/icons-react";
import {
  selectNextCard,
  selectPrevCard,
  useSelectedCard
} from "../../state";
import './Carousel.css';

const Carousel = () => {
  const [selectedCard, prevCard, nextCard] = useSelectedCard();

  const [label, sublabel] = selectedCard.label.split("\n");

  return <Row className="carousel">
    <Col m={3} className="carousel-prev">
      <span>{prevCard.label.split("\n")[0]}</span>
      <br/><span><Body2>{prevCard.label.split("\n")[1]}</Body2></span>
    </Col>
    <Col m={6}>
      <div className="carousel-center">
        <Button
          variant={Button.variants.secondary}
          label={"Föregående diagram"}
          icon={<ChevronLeftOutline/>}
          onClick={selectPrevCard}
          iconOnly
        />
        <div>
          <H3>{label}</H3>
          <span><Body1>{sublabel}</Body1></span>
        </div>
        <Button
          variant={Button.variants.secondary}
          label={"Nästa diagram"}
          icon={<ChevronRightOutline/>}
          onClick={selectNextCard}
          iconOnly
        />
      </div>
    </Col>
    <Col m={3} className="carousel-next">
      <span>{nextCard.label.split("\n")[0]}</span>
      <br/><span><Body2>{nextCard.label.split("\n")[1]}</Body2></span>
    </Col>
  </Row>;
}

export default Carousel;

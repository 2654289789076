import {useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const TemperatureNormGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `temperature/charts/${stationId}/${currentYear}`
  return <GraphURI
    uri={uri}
    lines={[
      {
        id: "T2mAVG_std_1991-2020",
        stroke: "#F82B37",
        name: "Normalvärden: 1991 – 2020",
        enabled: true,
      },
      {
        id: "T2mAVG_std_1961-1990",
        stroke: "#3B9CDF",
        name: "Normalvärden: 1961 – 1990",
        enabled: true,
      },
      {
        id: `T2mAVG_${currentYear - 1}`,
        stroke: "#1B2B36",
        name: `Dygnsmedel: ${currentYear - 1}`,
      }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    {...props} />;
};


export const TemperatureNormGraph1961_1990 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `temperature/charts/${stationId}/${currentYear}`
  return <GraphURI
    uri={uri}
    lines={[
      {
        id: "T2mAVG_std_1961-1990",
        stroke: "#3B9CDF",
        name: "Normalvärden: 1961 – 1990",
        enabled: true,
      },
      {
        id: `T2mAVG_${currentYear - 1}`,
        stroke: "#1B2B36",
        name: `Dygnsmedel: ${currentYear - 1}`,
      }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    {...props} />;
};

export const TemperatureNormGraph1991_2020 = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `temperature/charts/${stationId}/${currentYear}`
  return <GraphURI
    uri={uri}
    lines={[
      {
        id: "T2mAVG_std_1991-2020",
        stroke: "#F82B37",
        name: "Normalvärden: 1991 – 2020",
        enabled: true,
      },
      {
        id: `T2mAVG_${currentYear - 1}`,
        stroke: "#1B2B36",
        name: `Dygnsmedel: ${currentYear - 1}`,
      }
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    {...props} />;
};

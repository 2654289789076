import {useCurrentMonth, useCurrentStationIdAndYear} from "../../../state";
import {GraphURI} from "../GraphURI";

export const PrecipitationRecordGraph = (props) => {
  const [stationId, currentYear] = useCurrentStationIdAndYear();
  const uri = `precipitation/charts/${stationId}/${currentYear}`

  // Format the tooltip with getting the maxyear value from the dataset for Nederbördsrekord.
  const formatTooltipWithRecordYear = (date, payload) => {
    const precipitationRecord = payload.find(x => x.name === 'Nederbördsrekord');
    if (precipitationRecord !== undefined) {
      const maxYear = precipitationRecord.payload.maxyear;
      return `${date} ${maxYear}`;
    }
    return date;
  }

  return <GraphURI
    uri={uri}
    bars={[
      {
        id: `Dygnsnederbörd_max_full_period`,
        name: `Nederbördsrekord`,
        fill: "#74BCED",
        unit: 'mm',
        enabled: true
      },
      // {
      //   id: `Dygnsnederbörd_max_1961-1990`,
      //   name: `Nederbördsrekord 1961 – 1990`,
      //   fill: "#0C6BC4",
      //   unit: 'mm',
      //   enabled: false
      // },
      // {
      //   id: "Dygnsnederbörd_max_1991-2020",
      //   name: "Nederbördsrekord 1991 – 2020",
      //   fill: "#004591",
      //   unit: 'mm',
      //   enabled: false
      // },
      {
        id: `Dygnsnederbörd_${currentYear}`,
        fill: "#002863",
        name: `Dygnsnederbörd ${currentYear}`,
        unit: "mm",
        enabled: true
      }
    ]}
    tables={[
      {id: 'maxyear', name: 'År'}
    ]}
    ticks={['1/1', '1/2', '1/3', '1/4', '1/5', '1/6', '1/7', '1/8', '1/9', '1/10', '1/11', '1/12']}
    yAxisLabel='mm'
    tooltipLabelFormatter={formatTooltipWithRecordYear}
    {...props}
  />
};

export const PrecipitationMonthlyRecordGraph = (props) => {
  const [currentMonth] = useCurrentMonth();
  const filterData = data => data.filter(d => currentMonth === parseInt(d.date.split("/")[1]));
  return <PrecipitationRecordGraph month={currentMonth} {...props}
                      ticks={undefined} filterData={filterData}/>;
};

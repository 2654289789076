import { CONTEXT_PATH } from "../utils";

export async function getCards(stationId, month, parameter = 'temperature') {
  let url = `${CONTEXT_PATH}api/${parameter}/cards/${stationId}/${month}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('getCards failed');
  }
  const json = await response.json();
  return json;
}


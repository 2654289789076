export const CONTEXT_PATH = window.polopolyContextPath !== undefined ? window.polopolyContextPath : '/';

export const MONTHS = [
  {key: 1, value: 'Januari'},
  {key: 2, value: 'Februari'},
  {key: 3, value: 'Mars'},
  {key: 4, value: 'April'},
  {key: 5, value: 'Maj'},
  {key: 6, value: 'Juni'},
  {key: 7, value: 'Juli'},
  {key: 8, value: 'Augusti'},
  {key: 9, value: 'September'},
  {key: 10, value: 'Oktober'},
  {key: 11, value: 'November'},
  {key: 12, value: 'December'}
];

export const monthIndexToString = index => MONTHS.find(m => m.key === index).value.toLowerCase();

export const formatTemp = (number, plusSign=true) => {
  if (number == null) { return '-'; }
  const sign = number >= 0 ? (plusSign ? '+' : '') : '-';
  const value = Math.abs(number).toFixed(1);
  if (isNaN(value)) {
    return '-';
  }
  return `${sign}${value.toString().replace('.', ',')}°`
}
export const roundTemp = number => number.toFixed(1);
export const roundPrecipitation = number => {
  const decimals = number > -1 && number < 1 && number !== 0 ? 1 : 0;
  return number.toFixed(decimals);
}

export const formatPrecipitation = (number, plusSign=false) => {
  if (number == null) { return '-'; }
  const sign = number >= 0 ? (plusSign ? '+' : '') : '-';
  const decimals = number > -1 && number < 1 && number !== 0 ? 1 : 0;
  const value = Math.abs(number).toFixed(decimals);
  if (isNaN(value)) {
    return '-';
  }
  return `${sign}${value.toString().replace('.', ',')}mm`
}

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
function scrollTo(offset, callback) {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll)
      callback()
    }
  }

  window.addEventListener('scroll', onScroll)
  onScroll()
  window.scrollTo({
    top: offset,
    behavior: 'smooth'
  })
}

export const scrollToDocumentId = (id, yOffset = 0, callback = () => {}) => {
  const documentId = document.getElementById(id);
  if(documentId) {
    const y = documentId.getBoundingClientRect().top + window.pageYOffset + yOffset;
    scrollTo(y, callback);
  }
}
